import { driveTypeList, deviceTypeList, urgencyLists,AddressOffset, baudrateList, dataBitList, stopBitList, checkTypeList, StatisticalType, opnotFlagList, dataType, lengthList, AcquisitionFrequency, CRCList, VariableType, OperationMode, TreatmentMethod, urgencyList, sequenceList, FunCodeType, byteType, decodeType, FunCodeTypeCopy } from './gatewayArrays'

const filterByOptions = (value, options) => {
  const r = options.find(it => it.value === value)
  if (r) {
    return r.label
  } else {
    return options[0].label
  }
}
export const deviceTypeStatus = (value) => { //设备类型
  return filterByOptions(value, deviceTypeList)
}

export const driveTypeStatus = (value) => { //驱动类型
  return filterByOptions(value, driveTypeList)
}

export const baudrateStatus = (value) => { //波特率
  return filterByOptions(value, baudrateList)
}

export const dataBitStatus = (value) => { //数据位
  return filterByOptions(value, dataBitList)
}

export const stopBitStatus = (value) => { //停止位
  return filterByOptions(value, stopBitList)
}

export const checkTypeStatus = (value) => { //校验
  return filterByOptions(value, checkTypeList)
}
export const addressStatus = (value) => { //首地址偏移
  return filterByOptions(value, AddressOffset)
}
export const staristicalStatus = (value) => { //统计类型
  return filterByOptions(value, StatisticalType)
}
export const opnotFlagStatus = (value) => { //取反
  console.log(value);
  return filterByOptions(value, opnotFlagList)
}
export const dataTypeStatus = (value) => { //数据类型
  return filterByOptions(value, dataType)
}
export const lengthStatus = (value) => { //长度
  return filterByOptions(value, lengthList)
}
export const acquisitionStatus = (value) => { //采集频率
  return filterByOptions(value, AcquisitionFrequency)
}
export const crcStatus = (value) => { //CRC字节序
  return filterByOptions(value, CRCList)
}
export const variableStatus = (value) => { //变量类型
  return filterByOptions(value, VariableType)
}
export const operationStatus = (value) => { //操作模式
  return filterByOptions(value, OperationMode)
}
export const treatmentStatus = (value) => { //处理方式
  return filterByOptions(value, TreatmentMethod)
}
export const urgencyStatus = (value) => { //紧急程度
  return filterByOptions(value, urgencyList)
}
export const urgentStatus = (value) => { //紧急程度
  return filterByOptions(value, urgencyLists)
}

export const isMsgStatus = (value) => { //是否发送短信
  return filterByOptions(value, sequenceList)
}

export const FunCodeStatus = (value) => { //是否发送短信
  return filterByOptions(value, FunCodeType)
}

export const byteStatus = (value) => { //字节号
  return filterByOptions(value, byteType)
}

export const decodeStatus = (value) => { //解码类型
  return filterByOptions(value, decodeType)
}

export const funStatus = (value) => { //功能码
  return filterByOptions(value, FunCodeTypeCopy)
}