<template>
  <div class="q-role">
    <q-crud ref="crud" :schema="schema" />
  </div>
</template>

<script>
import api from "@/api/corpRole";
import BizCode from "@/utils/BizCode";

// @ is an alias to /src

export default {
  name: "QRole",
  data() {
    return {
      schema: {
        xType: "q-crud",
        api: api,
        subjectKey: "q-role", //关键字英文
        subjectName: "角色", //项目名中文
        dialogWidth: "400px",
        searchFields: [],
        addAuth: "ROLE:CREATE",
        gridColumns: [
          {
            xType: "q-table-column",
            label: "角色名称",
            prop: "name",
          },
          {
            xType: "q-table-column",
            label: "角色描述",
            prop: "description",
          },
          {
            xType: "q-table-column",
            label: "添加时间",
            render: (row) => {
              return this.$options.filters._date_time(row.createdDate);
            },
          },
          {
            xType: "q-table-column",
            label: "权限",
            render: (row) => {
              return row.resources.map((it) => `【${it.name}】`).join("");
            },
          },
          {
            xType: "q-table-column",
            label: "操作",
            prop: "address",
            width: 250,
            // eslint-disable-next-line no-unused-vars
            tpl: (row) => {
              //这里空下来做自定义渲染
              return [
                {
                  xType: "q-button",
                  text: "编辑",
                  type: "text",
                  auth:'ROLE:UPDATE',
                  click: (event) => {
                    this.$refs["crud"].update(event, row);
                  },
                },
                {
                  xType: "q-button",
                  text: "设置权限",
                  auth:'ROLE:BIND_AUTHORITY',
                  type: "text",
                  click: () => {
                    this.$router.push({
                      name: "Authority",
                      query: {
                        roleId: row.id,
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  text: "删除",
                  auth:'ROLE:REMOVE',
                  type: "text",
                  click: async () => {
                    try {
                      await this.$confirm("确认删除?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      });
                      //请求服务器
                      const result = await api.del(row.id);
                      if (result.code === BizCode.OK) {
                        //若删除成功则刷新数据库
                        this.$refs["crud"].syncServer();
                      }
                    } catch (err) {
                      //取消按钮
                      if (err !== "cancel") {
                        throw err;
                      }
                    }
                  },
                },
              ];
            },
          },
        ],
        formFields: [
          {
            xType: "q-field",
            fType: "q-hidden",
            name: "mode",
            label: "mode",
            prop: "mode",
            labelWidth: '100px'
          },
          {
            xType: "q-field",
            fType: "q-hidden",
            name: "id",
            label: "id",
            prop: "id",
          },
          {
            xType: "q-field",
            fType: "q-input",
            name: "角色名称",
            label: "角色名称",
            prop: "name",
            max: 20,
            labelWidth: '100px',
            rules: [
              {
                required: true,
                message: "角色名称不能为空",
                trigger: "blur",
              },
            ],
          },
          {
            xType: "q-field",
            fType: "q-input",
            name: "角色描述",
            label: "角色描述",
            max: 50,
            labelWidth: '100px',
            prop: "description",
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
