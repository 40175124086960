var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-authority"},[_c('q-page',{attrs:{"schema":{
			xType: 'q-page',
			title: '权限设置',
			items: [
				{
					id: "q-authority-form",
					labelWidth: '150px',
					xType: 'q-form',
					items: [].concat( _vm.checkboxes ),
				},
				{
					xType: 'q-button-panel',
					paddingLeft: '150px',
					items: [
						{
							xType: 'q-button',
							text: '确定',
							click: function (event) {
								this$1.ok();
							},
						},
						{
							xType: 'q-button',
							text: '取消',
							click: function () {
								this$1.$back();
							},
						} ],
				} ],
		},"path":"/"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }