<template>
  <div class="form-panel">
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item
        label="旧密码"
        prop="oldPwd"
        :rules="[
          { required: true, message: '旧密码不能为空', trigger: 'change' },
          {
            pattern: regexs.pwd,
            message: '请输入8~15位字母和数字组成的密码',
            trigger: 'change',
          },
        ]"
      >
        <el-input
          v-model="form.oldPwd"
          placeholder="请输入新密码"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item
        label="新密码"
        prop="newPwd"
        :rules="[
          { required: true, message: '新密码不能为空', trigger: 'change' },
          {
            pattern: regexs.pwd,
            message: '请输入8~15位字母和数字组成的密码',
            trigger: 'change',
          },
        ]"
      >
        <el-input
          v-model="form.newPwd"
          placeholder="请输入新密码"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item
        label="确认新密码"
        prop="reNewPwd"
        :rules="[
          {
            required: true,
            message: '确认新密码不能为空',
            trigger: 'change',
          },
          {
            validator: validateReNewPwd,
            trigger: 'change',
          },
        ]"
      >
        <el-input
          v-model="form.reNewPwd"
          placeholder="请再次输入新密码"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button v-if="form.passwordChangeComplete" @click="$goHome()"
          >取消</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BizCode from "@/utils/BizCode";
import api from "@/api/corpUser";
import { regexs } from "@/utils/validate";
import AuthClient from "@/utils/AuthClient";

export default {
  async mounted() {
    // const result = await api.self();
    // if (result.code === BizCode.OK) {
    //   const { data: self = {} } = result;
    //   const { phone } = self;
    //   this.form.phone = phone;
    // }
  },
  data() {
    return {
      regexs: regexs,
      form: {
        oldPwd: "",
        newPwd: "",
        reNewPwd: "",
      },
    };
  },
  methods: {
    validateReNewPwd(rule, value, callback) {
      if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致，请重新输入"));
      } else {
        callback();
      }
    },

    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //验证成功
          const result = await api.updatePwd({
            oldPassword: this.form.oldPwd,
            newPassword: this.form.newPwd,
          });
          if (result.code === BizCode.OK) {
            //修改密码成功后
            this.$message.success("修改成功");
            //退出
            AuthClient.getInstance().logout();
          }
          return true;
        } else {
          //验证失败
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-panel {
  margin-top: 50px;
  width: 600px;
}
</style>
