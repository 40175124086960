// 新增配置中的第一列
export const driveTypeList = [ // 驱动类型
  {
    value: 0,
    label: 'ModBusRTU'
  },
  {
    value: 17,
    label: 'DLT645_2007'
  },
]

export const deviceTypeList = [
  { value: 0, label: "空压机" },
  { value: 1, label: "干燥机" },
  { value: 2, label: "智控系统" },
  { value: 3, label: "流量计" },
  { value: 4, label: "智能电表" },
  { value: 5, label: "冷却系统" },
  { value: 6, label: "传感器" },
  { value: 7, label: "其他类型" },
]

export const driveSmallList = [ // 驱动类型
  {
    value: 1,
    label: 'MODBUS_TCP'
  },
]

export const baudrateList = [ // 波特率
  { value: 110, label: '110' },
  { value: 300, label: '300' },
  { value: 600, label: '600' },
  { value: 1200, label: '1200' },
  { value: 2400, label: '2400' },
  { value: 4800, label: '4800' },
  { value: 9600, label: '9600' },
  { value: 14400, label: '14400' },
  { value: 19200, label: '19200' },
  { value: 38400, label: '38400' },
  { value: 56000, label: '56000' },
  { value: 57600, label: '57600' },
  { value: 115200, label: '115200' },
  { value: 128000, label: '128000' },
  { value: 256000, label: '256000' },
]

export const dataBitList = [ // 数据位
  { value: 7, label: '7' },
  { value: 8, label: '8' },
]

export const stopBitList = [ // 停止位
  { value: 1, label: '1' },
  { value: 2, label: '2' },
]

export const checkTypeList = [ // 校验类型
  { value: 0, label: '无校验' },
  { value: 1, label: '奇校验' },
  { value: 2, label: '偶校验' },
]

export const AddressOffset = [ // 首地址偏移
  {
    value: 0,
    label: '0'
  }, {
    value: 1,
    label: '1'
  }
]
export const opnotFlagList = [ // 取反
  {
    value: true,
    label: '取反'
  }, {
    value: false,
    label: '不取反'
  }
]
export const dataType = [ // 数据类型
  {
    value: 1,
    label: '线圈状态[01]'
  }, {
    value: 2,
    label: '输入状态[02]'
  }, {
    value: 3,
    label: '保持寄存器[03]'
  }, {
    value: 4,
    label: '输入寄存器[04]'
  }
]

export const lengthList = [ // 长度
  {
    value: 1,
    label: '1'
  }, {
    value: 2,
    label: '2'
  }, {
    value: 4,
    label: '4'
  }
]
export const AcquisitionFrequency = [ // 采集频率
  {
    value: 0,
    label: '低频'
  }, {
    value: 1,
    label: '高频'
  }
]
export const CRCList = [ // CRC字节序
  {
    value: 0,
    label: '小端'
  }, {
    value: 1,
    label: '大端'
  }
]
export const VariableType = [ // 变量类型
  {
    value: 0,
    label: '整数(补码)'
  }, {
    value: 1,
    label: '无符号整数'
  }, {
    value: 2,
    label: '整数(原始码)'
  }
]
export const OperationMode = [ // 操作模式
  {
    value: 0,
    label: '无'
  }, {
    value: 1,
    label: '按位读取'
  }, {
    value: 2,
    label: '按字节读取'
  }
]
export const byteType = [ // 字节号
  {
    value: 0,
    label: '低字节'
  }, {
    value: 1,
    label: '高字节'
  }
]
export const TreatmentMethod = [ // 处理方式
  {
    value: 0,
    label: 'AB'
  }, {
    value: 1,
    label: 'BA'
  }, {
    value: 2,
    label: 'ABCD'
  }, {
    value: 3,
    label: 'CDAB'
  }, {
    value: 4,
    label: 'BADC'
  }, {
    value: 5,
    label: 'DCBA'
  }, {
    value: 6,
    label: 'ABCDEFGH'
  }, {
    value: 7,
    label: 'GHEFCDAB'
  }, {
    value: 8,
    label: 'BADCFEHG'
  }, {
    value: 9,
    label: 'HGFEDCBA'
  }
]
export const urgencyLists = [ // 紧急程度(模板)
  { value: 1, label: "低级" },
  { value: 2, label: "中级" },
  { value: 3, label: "高级" },
]
export const urgencyList = [ // 紧急程度
  { value: 1, label: '低级' },
  { value: 2, label: '一般' },
  { value: 3, label: '中级' },
  { value: 4, label: '中高' },
  { value: 5, label: '高级' },
  { value: 6, label: '极高' },
]
export const leadByteList = [ // 前导地址
  { value: 0, label: '空' },
  { value: 1, label: 'FE' },
  { value: 2, label: 'FEFE' },
  { value: 3, label: 'FEFEFE' },
  { value: 4, label: 'FEFEFEFE' },
]

export const isMsgList = [ // 是否发送短信
  {
    value: 0,
    label: '是'
  }, {
    value: 1,
    label: '否'
  }
]

export const sequenceList = [ // 关联点序列
  {
    value: 0,
    label: '从数据点中选择'
  }
]

export const StatisticalType = [ // 统计类型
  {
    value: 0,
    label: '累积量'
  }, {
    value: 1,
    label: '瞬时量'
  }
]

export const FunCodeType = [ // 功能码
  {
    value: 1,
    label: 'CIO'
  }, {
    value: 2,
    label: 'HR'
  }, {
    value: 3,
    label: 'AR'
  }, {
    value: 4,
    label: 'LR'
  }, {
    value: 5,
    label: 'DM'
  }, {
    value: 6,
    label: 'TIMER_OR_COUNTER_PV'
  }, {
    value: 7,
    label: 'TIMER_OR_COUNTER_STATUS'
  }, {
    value: 8,
    label: 'EM'
  }
]
export const FunCodeTypeCopy = [ // 功能码
  {
    value: 1,
    label: '读输入线圈[01]'
  }, {
    value: 2,
    label: '读输入线圈[02]'
  }, {
    value: 3,
    label: '读保持寄存器[03]'
  }, {
    value: 4,
    label: '读输入寄存器[04]'
  }
]

export const decodeType = [ // 解码类型
  {
    value: 0,
    label: '布尔型'
  }, {
    value: 1,
    label: '无符号8位整数'
  }, {
    value: 2,
    label: '无符号16位整数'
  }, {
    value: 4,
    label: '无符号32位整数'
  }, {
    value: 8,
    label: '无符号64位整数'
  }, {
    value: 17,
    label: '有符号8位整数'
  }, {
    value: 18,
    label: '有符号16位整数'
  }, {
    value: 20,
    label: '有符号32位整数'
  }, {
    value: 24,
    label: '有符号64位整数'
  }, {
    value: 36,
    label: '单精度浮点数'
  }, {
    value: 40,
    label: '双精度浮点数'
  }
]