import { request } from "@/utils/request";
import { loading } from "@/utils/index";

const BASE_PATH = "/customer";

//客户
class Api {
  //新增
  @loading()
  async add(form) {
    return request.post(`${BASE_PATH}`, form);
  }

  //修改
  @loading()
  async update(mcid, form) {
    return request.put(`${BASE_PATH}/${mcid}`, form);
  }

  /** 客户详情 */
  @loading({ delay: 0 })
  async detail(mcid) {
    return request.get(`${BASE_PATH}/${mcid}`);
  }

  /** 删除客户 */
  @loading()
  async enable(mcid,disabled) {
    return request.put(`${BASE_PATH}/enable/${mcid}?disabled=${disabled}`);
  }

  /** 分页获取客户列表 */
  @loading({ delay: 0 })
  async getPageList(params) {
    return request.get(`${BASE_PATH}/page`, { params: params });
  }

  /** 获取订阅列表 */
  @loading({ delay: 0 })
  async getSubscription(mcid) {
    return request.get(`${BASE_PATH}/${mcid}/getSubscription`);
  }

  //修改
  @loading({ delay: 500 } )
  async subscribe(mcid, form) {
    return request.put(`${BASE_PATH}/${mcid}/subscribe`, form);
  }
}

export default new Api();
