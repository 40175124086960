var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-view-customer"},[_c('q-page',{attrs:{"schema":{
			xType: 'q-page',
			title: this.$route.query.mode === 'view' ? '查看客户' : this.$route.query.mode === 'add' ? '新增客户' : '修改客户',
			items: [
				{
					id: "q-customer-form",
					labelWidth: '150px',
					disabled: _vm.$route.query.mode === 'view',
					xType: 'q-form',
					items: [
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '客户公司名称',
							label: '客户公司名称',
							max: 20,
							placeholder: '请输入客户公司名称',
							prop: 'name',
							rules: [
								{
									required: true,
									message: '租户公司名称不能为空',
									trigger: 'blur',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '客户公司简称',
							label: '客户公司简称',
							max: 20,
							placeholder: '请输入租户简称',
							prop: 'simpleName',
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '负责人',
							label: '负责人',
							max: 20,
							prop: 'managerName',
							placeholder: '请输入负责人姓名',
							rules: [
								{
									required: true,
									message: '负责人姓名不能为空',
									trigger: 'blur',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '负责人手机',
							label: '负责人手机',
							prop: 'managerPhone',
							placeholder: '请输入负责人手机号码',
							rules: [
								{
									required: true,
									message: '负责人手机号码不能为空',
									trigger: 'blur',
								},
								{
									pattern: _vm.regexs.mobile,
									message: '请输入正确的手机号',
									trigger: 'change',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '企业邮箱',
							label: '企业邮箱',
							prop: 'email',
							placeholder: '请输入企业邮箱',
							rules: [
								{
									type: 'email',
									message: '请输入正确的邮箱地址',
									trigger: 'change',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '公司电话',
							label: '公司电话',
							prop: 'phone',
							placeholder: '请输入租户公司电话',
							rules: [
								{
									pattern: _vm.regexs.phoneRegWithArea2,
									message: '请输入正确的电话',
									trigger: 'change',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '社会统一信用代码',
							label: '社会统一信用代码',
							prop: 'uscc',
							placeholder: '请输入社会统一信用代码',
							rules: [
								{
									required: true,
									message: '社会统一信用代码不能为空',
									trigger: 'blur',
								},
								{
									pattern: _vm.regexs.uscc,
									message: '请输入正确的社会统一信用代码',
									trigger: 'change',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-industry-cascader',
							name: '所属行业',
							label: '所属行业',
							prop: 'industry',
							placeholder: '请选择所属行业',
							rules: [
								{
									required: true,
									message: '请选择所属行业',
									trigger: 'blur',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '客户传真',
							label: '客户传真',
							prop: 'fax',
							placeholder: '请输入租户公司传真',
							rules: [
								{
									pattern: _vm.regexs.phoneRegWithArea2,
									message: '请输入正确的传真',
									trigger: 'change',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-location-cascader',
							name: '客户地址',
							label: '客户地址',
							prop: 'location',
							placeholder: '请选择省份、市、县/区',
							rules: [
								{
									required: true,
									message: '请选择省份、市、县/区',
									trigger: 'blur',
								} ],
						},
						{
							xType: 'q-field',
							fType: 'q-address',
							name: '',
							label: '',
							prop: 'address',
						},
						{
							xType: 'q-field',
							fType: 'q-input',
							name: '备注',
							label: '备注',
							max: 300,
							placeholder: '请输入备注',
							prop: 'description',
						},
						{
							xType: 'q-field',
							fType: 'q-image-upload',
							name: 'logo',
							label: 'logo',
							prop: 'logo',
						} ],
				},
				{
					xType: 'q-button-panel',
					items: [
						{
							xType: 'q-button',
							text: '确定',
							type: 'primary',
							visible: _vm.$route.query.mode !== 'view',
							click: function (event) {
								this$1.ok();
							},
						},
						{
							xType: 'q-button',
							text: '取消',
							click: function () {
								this$1.$back();
							},
						} ],
				} ],
		},"path":"/"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }