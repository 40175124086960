<template>
	<div class="q-view-customer">
		<q-page
			:schema="{
				xType: 'q-page',
				title: this.$route.query.mode === 'view' ? '查看客户' : this.$route.query.mode === 'add' ? '新增客户' : '修改客户',
				items: [
					{
						id: `q-customer-form`,
						labelWidth: '150px',
						disabled: $route.query.mode === 'view',
						xType: 'q-form',
						items: [
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '客户公司名称',
								label: '客户公司名称',
								max: 20,
								placeholder: '请输入客户公司名称',
								prop: 'name',
								rules: [
									{
										required: true,
										message: '租户公司名称不能为空',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '客户公司简称',
								label: '客户公司简称',
								max: 20,
								placeholder: '请输入租户简称',
								prop: 'simpleName',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '负责人',
								label: '负责人',
								max: 20,
								prop: 'managerName',
								placeholder: '请输入负责人姓名',
								rules: [
									{
										required: true,
										message: '负责人姓名不能为空',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '负责人手机',
								label: '负责人手机',
								prop: 'managerPhone',
								placeholder: '请输入负责人手机号码',
								rules: [
									{
										required: true,
										message: '负责人手机号码不能为空',
										trigger: 'blur',
									},
									{
										pattern: regexs.mobile,
										message: '请输入正确的手机号',
										trigger: 'change',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '企业邮箱',
								label: '企业邮箱',
								prop: 'email',
								placeholder: '请输入企业邮箱',
								rules: [
									{
										type: 'email',
										message: '请输入正确的邮箱地址',
										trigger: 'change',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '公司电话',
								label: '公司电话',
								prop: 'phone',
								placeholder: '请输入租户公司电话',
								rules: [
									{
										pattern: regexs.phoneRegWithArea2,
										message: '请输入正确的电话',
										trigger: 'change',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '社会统一信用代码',
								label: '社会统一信用代码',
								prop: 'uscc',
								placeholder: '请输入社会统一信用代码',
								rules: [
									{
										required: true,
										message: '社会统一信用代码不能为空',
										trigger: 'blur',
									},
									{
										pattern: regexs.uscc,
										message: '请输入正确的社会统一信用代码',
										trigger: 'change',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-industry-cascader',
								name: '所属行业',
								label: '所属行业',
								prop: 'industry',
								placeholder: '请选择所属行业',
								rules: [
									{
										required: true,
										message: '请选择所属行业',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '客户传真',
								label: '客户传真',
								prop: 'fax',
								placeholder: '请输入租户公司传真',
								rules: [
									{
										pattern: regexs.phoneRegWithArea2,
										message: '请输入正确的传真',
										trigger: 'change',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-location-cascader',
								name: '客户地址',
								label: '客户地址',
								prop: 'location',
								placeholder: '请选择省份、市、县/区',
								rules: [
									{
										required: true,
										message: '请选择省份、市、县/区',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-address',
								name: '',
								label: '',
								prop: 'address',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '备注',
								label: '备注',
								max: 300,
								placeholder: '请输入备注',
								prop: 'description',
							},
							{
								xType: 'q-field',
								fType: 'q-image-upload',
								name: 'logo',
								label: 'logo',
								prop: 'logo',
							},
						],
					},
					{
						xType: 'q-button-panel',
						items: [
							{
								xType: 'q-button',
								text: '确定',
								type: 'primary',
								visible: $route.query.mode !== 'view',
								click: (event) => {
									this.ok();
								},
							},
							{
								xType: 'q-button',
								text: '取消',
								click: () => {
									this.$back();
								},
							},
						],
					},
				],
			}"
			path="/"
		>
		</q-page>
	</div>
</template>

<script>
// @ is an alias to /src
import api from '@/api/customer';
// import { getIndustry } from '@/utils/array';
// import appVersionApi from '@/api/appVersion';
// import Enumerable from 'linq';
import BizCode from '@/utils/BizCode';
import { findComponentById } from '@/utils/index';
import { regexs } from '@/utils/validate';
import Location from '@/assets/json/Location.json';
export default {
	name: 'QEditCustomer',
	async mounted() {
		if (this.$route.query.mode === 'view' || this.$route.query.mode === 'update') {
			const result = await api.detail(this.$route.query.mcid);
			if (result.code === BizCode.OK) {
				const { data } = result;
				const { province, city, district, primaryIndustryId, secondaryIndustryId, minorIndustryId, ...fields } = data;
				const frm = findComponentById('q-customer-form', this);
				frm.setData({
					...fields,
					industry: [primaryIndustryId, secondaryIndustryId, minorIndustryId],
					location: [province, city, district],
				});
			}
		}
	},
	data() {
		return {
			regexs: regexs,
			options: this.getTreeData(this.formatLocation(Location.districts[0].districts)),
		};
	},
	methods: {
		find(fields, data) {
			let long = '';
			for (const itp of data) {
				for (const itc of itp.children) {
					if (itc.children && itc.children.length > 0) {
						for (const itd of itc.children) {
							if (fields.location[2] === itd.value) {
								long = itd.center;
								return long
							}
						}
					}
				}
			}
      
		},
		async ok() {
			const frm = findComponentById('q-customer-form', this);
			console.log(frm.getData());
			frm.validate(async (valid) => {
				//验证成功
				if (valid) {
					const fields = frm.getData();
					let result = null;
					let long = this.find(fields,this.options);
  
					let longitude = long && long.split(',') ? long.split(',')[0] : '';
					let latitude = long && long.split(',') ? long.split(',')[1] : '';
					const {
						location,
						industry,
						//mcid,
						// eslint-disable-next-line no-unused-vars
						createdDate,
						// eslint-disable-next-line no-unused-vars
						disabled,
						...others
					} = fields;
					console.log(fields);
					console.log({
						...others,
						province: location?.[0],
						city: location?.[1],
						district: location?.[2],
						primaryIndustryId: industry?.[0],
						secondaryIndustryId: industry?.[1],
						minorIndustryId: industry?.[2],
						longitude: longitude,
						latitude: latitude,
					});
					if (this.$route.query.mode === 'add') {
						result = await api.add({
							...others,
							province: location?.[0],
							city: location?.[1],
							district: location?.[2],
							primaryIndustryId: industry?.[0],
							secondaryIndustryId: industry?.[1],
							minorIndustryId: industry?.[2],
							longitude: longitude,
							latitude: latitude,
						});
					} else {
						result = await api.update(this.$route.query.mcid, {
							...others,
							province: location?.[0],
							city: location?.[1],
							district: location?.[2],
							primaryIndustryId: industry?.[0],
							secondaryIndustryId: industry?.[1],
							minorIndustryId: industry?.[2],
							longitude: longitude,
							latitude: latitude,
						});
					}
					if (result.code === BizCode.OK) {
						this.$back();
					}
				}
			});
			//return result;
		},

		getTreeData(data) {
			data.forEach((r) => {
				if (r.children.length < 1) {
					r.children = undefined;
				} else {
					this.getTreeData(r.children);
				}
			});
			return data;
		},

		formatLocation(locationArr) {
			const arr = [];
			let obj = {};
			locationArr.forEach((item) => {
				const tmp = { ...item, children: [] };
				if (tmp.districts) {
					tmp.children = this.formatLocation(tmp.districts);
					obj = {
						value: tmp.adcode ? tmp.adcode : tmp.id,
						label: tmp.name,
						center: tmp.center,
						children: tmp.children,
					};
				}
				arr.push(obj);
			});
			return arr;
		},
	},
};
</script>
