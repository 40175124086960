<template>
  <div>
    <div class="page_title">
      <span>{{ titleStates }}-模板信息</span>
    </div>
    <el-form
      :model="templateForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="模板名称" prop="name">
        <el-input
          v-model="templateForm.name"
          placeholder="请输入模板名称"
          maxlength="50"
          :disabled="status === 'read'"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备类别" prop="deviceType">
        <el-select
          v-model="templateForm.deviceType"
          placeholder="请选择设备类别"
          :disabled="status === 'read'"
        >
          <el-option
            v-for="item in deviceTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="生产厂家" prop="manufacturer">
        <el-input
          v-model="templateForm.manufacturer"
          placeholder="请输入生产厂家"
          maxlength="50"
          :disabled="status === 'read'"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备型号" prop="deviceModel">
        <el-input
          v-model="templateForm.deviceModel"
          placeholder="请输入设备型号"
          maxlength="50"
          :disabled="status === 'read'"
        ></el-input>
      </el-form-item>
      <el-form-item label="驱动类型" prop="driveType">
        <el-select
          v-model="templateForm.driveType"
          placeholder="请选择驱动类型"
          :disabled="status === 'read'"
        >
          <el-option
            v-for="item in driveTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleNextStep('ruleForm')"
          v-if="!gatawayTempId"
          >下一步</el-button
        >
        <el-button
          type="primary"
          @click="handleNextStep('ruleForm')"
          v-if="status !== 'read' && status !== 'add'"
          :disabled="status === 'read'"
          >确定</el-button
        >
        <el-button
          @click="goBack()"
          v-if="status !== 'read'"
          :disabled="status === 'read'"
          >取消</el-button
        >
        <el-button v-if="status === 'read'" @click="goBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <template-data-config
      v-if="flag"
      :templateInfo="templateForm"
    ></template-data-config>
  </div>
</template>

<script>
import TemplateDataConfig from "./TemplateDataConfig.vue";
import { driveTypeList, deviceTypeList } from "@/utils/gatewayArrays";
import applicationApi from "@/api/application";
export default {
  components: {
    TemplateDataConfig,
  },
  data() {
    return {
      status: "",
      gatawayTempId: Number(this.$route.query.id),
      flag: false,
      titleStates: "",
      templateForm: {
        name: "",
        deviceType: "",
        manufacturer: "",
        deviceModel: "",
        driveType: "",
      },
      configure: {
        //配置详情
        basicPointList: [], //基本点
        virtualPointList: [], //虚拟点
        alertRuleList: [], //报警点
      },
      templateInfo: {},
      //设备类别
      deviceTypeList: deviceTypeList,
      //驱动类型
      driveTypeList: driveTypeList,
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        deviceType: [
          { required: true, message: "请选择设备类别", trigger: "change" },
        ],
        manufacturer: [
          { required: true, message: "请输入生产厂家", trigger: "change" },
        ],
        deviceModel: [
          { required: true, message: "请输入设备型号", trigger: "blur" },
        ],
        driveType: [
          { required: true, message: "请选择驱动类型", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //详情
    async fetchData() {
      if (this.gatawayTempId) {
        const { data } = await applicationApi.pointsDetails(this.gatawayTempId);
        this.templateForm.name = data.name;
        this.templateForm.deviceType = data.deviceType;
        this.templateForm.manufacturer = data.manufacturer;
        this.templateForm.deviceModel = data.deviceModel;
        this.templateForm.driveType = data.driveType;
        this.configure = data.configure; //有的设备下有数据
      }
    },

    // 1、所有字段的长度校验为100个字符,50个字；
    // 2、[模板名称]做唯一性校验。
    handleNextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const configure = this.configure;
          const concatForm = { ...this.templateForm, configure };
          // 下一步  调用接口新增操作，下一个页面都是编辑操作
          if (this.status === "add") {
            this.templateInfo = this.templateForm;
            applicationApi.pointsAdd(concatForm).then((r) => {
              this.$message.success("保存成功");
              this.$router.push({
                name: "TemplateDataConfig",
                query: {
                  action: "add",
                  templateInfos: this.templateInfo, //传值方式待修改
                  id: r.data.id, //传值 id 给模板页面新增使用
                  driveType: this.templateForm.driveType,
                },
              });
            });
            // 确定
          } else if (this.status === "edit") {
            applicationApi
              .pointsEdit(this.gatawayTempId, concatForm)
              .then(() => {
                this.$message.success("编辑成功");
                this.fetchData();
                setTimeout(() => {
                  this.$router.push({ name: "TemplateManage" });
                }, 1000);
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    goBack() {
      window.history.go(-1);
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.status = this.$route.query.action;
    switch (this.status) {
      case "add":
        this.titleStates = "新增模板";
        break;
      case "read":
        this.titleStates = "查看模板";
        break;
      default:
        this.titleStates = "编辑模板";
    }
  },
};
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  width: 50% !important;
}
</style>