<template>
	<div class="q-authority">
		<q-page
			:schema="{
				xType: 'q-page',
				title: '权限设置',
				items: [
					{
						id: `q-authority-form`,
						labelWidth: '150px',
						xType: 'q-form',
						items: [...checkboxes],
					},
					{
						xType: 'q-button-panel',
						paddingLeft: '150px',
						items: [
							{
								xType: 'q-button',
								text: '确定',
								click: (event) => {
									this.ok();
								},
							},
							{
								xType: 'q-button',
								text: '取消',
								click: () => {
									this.$back();
								},
							},
						],
					},
				],
			}"
			path="/"
		>
		</q-page>
	</div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import BizCode from '@/utils/BizCode';
import { findComponentById } from '@/utils/index';
import { Notification } from 'element-ui';
import api from '@/api/corpRole';
import Enumerable from 'linq';
import Vue from 'vue';
// @ is an alias to /src
const zh_map = {
	ROLE: '角色管理',
	CORP_USER: '内部用户管理',
	APPLICATION: '应用管理',
	VERSION: '版本管理',
	CUSTOMER: '客户管理',
};

export default {
	name: 'QAuthority',
	async mounted() {
		const authorities = await this.getAllAuthorities();
		const detail = await api.detail(this.$route.query.roleId);
		if (detail.code === BizCode.OK) {
			//console.log(detail.data,"detail");
			const form = findComponentById('q-authority-form', this);
			const formData = form.getData();
			for (let r of detail?.data?.resources ?? []) {
				for (let authority of authorities) {
					if ((authority?.opts ?? []).map((m) => m.value).includes(r.resource)) {
						//
						formData[authority.name].push(r.resource);
					}
				}
			}
		}
	},
	data() {
		return { checkboxes: [] };
	},
	methods: {
		async getAllAuthorities() {
			const result = await api.getAllAuthorities();
			if (result.code === BizCode.OK) {
				//
				const authorities = Enumerable.from(result.data)
					.groupBy('$.category', null, (key, group) => {
						return {
							xType: 'q-field',
							fType: 'q-checkboxes',
							label: '',
							allText: zh_map[key],
							name: key,
							prop: key,
							opts: group
								.select((r) => {
									return {
										label: r.name,
										value: r.resource,
									};
								})
								.toArray(),
						};
					})
					.toArray();
				//初始化表单观察者属性,此处未来要重点改进
				const form = findComponentById('q-authority-form', this);
				let obj = {};
				for (let authority of authorities) {
					obj[authority.name] = Vue.observable([]);
				}
				form.setData(obj);
				this.checkboxes = [...authorities];
				console.log(authorities, 'authorities');

				return authorities;
			}
		},
		async ok() {
			const form = findComponentById('q-authority-form', this);
			const formData = form.getData();
			console.log(formData, 'authority form data');

			let arr = [];
			for (let key of Object.keys(formData)) {
				arr = [...arr, ...formData[key]];
			}

			console.log(arr);
			if (arr.length === 0) {
				Notification({
					title: '提示',
					message: '选择权限',
					type: 'error',
				});
				return;
			}

			const result = await api.bindCorpAuthority(this.$route.query.roleId, [...arr]);
			if (result.code === BizCode.OK) {
				this.$back();
			}
		},
	},
};
</script>
