<template>
  <div class="list_table">
    <el-form
      :model="datePointForm"
      :rules="fromaDataRules"
      ref="virtualPointForm"
    >
      <el-table
        class="bg_table position"
        :data="datePointForm.detailList"
        ref="pointData"
        style="width: 100%"
      >
        <el-table-column label="名称" show-overflow-tooltip fixed>
          <template slot-scope="scope">
            <el-form-item
              :prop="'detailList.' + scope.$index + '.name'"
              :rules="fromaDataRules.name"
            >
              <span v-if="scope.row.action == 'view'">{{
                scope.row.name
              }}</span>
              <el-input
                v-else
                v-model="scope.row.name"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="单位">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.description }}
              </span>
              <el-input
                v-else
                v-model="scope.row.description"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="统计类型">
          <template slot-scope="scope">
            <el-form-item
              :prop="'detailList.' + scope.$index + '.acquisitionType'"
              :rules="fromaDataRules.acquisitionType"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.acquisitionType | staristicalStatus }}
              </span>
              <el-select
                v-model="scope.row.acquisitionType"
                v-else
                placeholder="请选择统计类型"
              >
                <el-option
                  v-for="item in statisticalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="公式">
          <template slot-scope="scope">
            <el-form-item
              :prop="'detailList.' + scope.$index + '.rawFormulaName'"
              :rules="fromaDataRules.rawFormulaName"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.rawFormulaName }}
              </span>
              <el-popover v-else placement="right" width="400" trigger="focus">
                <div class="input_popover">
                  <p class="popover_title">公式</p>
                  <div class="pl-20 pr-20">
                    <p class="popover_name">1.输入规则</p>
                    <p>【虚拟点】界面输入“空格@”召唤基本点和虚拟点列表。</p>
                    <p>【报警规则】界面输入“空格@”召唤基本点和虚拟点列表。</p>
                    <p class="popover_name">2.运算符</p>
                    <p>
                      <label>【虚拟点】</label>
                      <span>加(+) 减(-) 乘(*) 除(/)</span>
                    </p>
                    <p>
                      <label>【报警规则】</label>
                      <span
                        >逻辑运算符
                        大于（&gt;）小于（&lt;）与（&amp;&amp;）或（||）</span
                      >
                    </p>
                  </div>
                </div>
                <el-input
                  slot="reference"
                  v-model="scope.row.rawFormulaName"
                  @input="handleInput(scope.row)"
                  type="textarea"
                  autosize
                  maxlength="300"
                ></el-input>
              </el-popover>
            </el-form-item>
            <ul v-if="scope.row.isFormula" class="formula_list">
              <li
                v-for="(item, index) in selectList"
                :key="index"
                @click="hansleSelect(scope.row, item)"
              >
                {{ `${item.type === "BASIC" ? "【基本点】" : "【虚拟点】"}`
                }}{{ item.name }}#{{ item.type }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-form-item>
              <div v-if="scope.row.action == 'view'">
                <el-button type="text" @click="handleEditData(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleDelData(scope.row, scope.$index)"
                  >删除</el-button
                >
              </div>
              <div v-else>
                <el-button
                  type="text"
                  @click="handleSaveData(scope.row, scope.$index)"
                  >保存</el-button
                >
                <el-button
                  type="text"
                  @click="handleCancel(scope.row, scope.$index)"
                  >取消</el-button
                >
              </div>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="tar mt-15">
      <!-- <Pagination v-if="total>=20" :total="total" :page.sync="searchForm.page" :limit.sync="searchForm.size" @pagination="fetchData" /> -->
    </div>
    <div class="mb-20 mt-10">
      <el-button type="primary" @click="handleAddRow()" :disabled="btnDisabled"
        ><i class="el-icon-plus"></i> 增加一行</el-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { StatisticalType } from "@/utils/gatewayArrays";
import { staristicalStatus } from "@/utils/gatewayFilters";
// import { virtualPointList, virtualPointCreate, virtualPointUpdate, virtualPointDelete,pointAll } from '@/api/gatewayConfig';
import applicationApi from "@/api/application";
import store from "@/store";
import { mapState } from "vuex";
let that;
export default {
  name: "pointItem",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  filters: {
    staristicalStatus,
  },
  props: {
    configId: Number,
    tepInfoObj: {
      type: Object,
      defalut: {},
    },
    gatawayTempId: Number,
  },
  data() {
    //这里存放数据
    return {
      basicList: [],
      virList: [],
      pointAll: [],
      virtualPointLists: [],
      configure: {
        //配置详情
        basicPointList: [], //基本点
        virtualPointList: [], //虚拟点
        alertRuleList: [], //报警点
      },
      tepInfoObjRep: {},
      gatawayTempIdRep: null,
      action: "create",
      btnDisabled: false,
      pointList: [],
      datePointForm: {
        detailList: [],
      },
      searchForm: {
        page: 1,
        size: 20,
      },
      total: 0,
      selectList: [],
      fromaDataRules: {
        name: [{ required: true, message: "名称不能为空", trigger: "submit" }],
        description: [
          { required: true, message: "描述不能为空", trigger: "submit" },
        ],
        acquisitionType: [
          { required: true, message: "请选择统计类型", trigger: "submit" },
        ],
        rawFormulaName: [
          { required: true, message: "公式不能为空", trigger: "submit" },
        ],
      },
      statisticalList: StatisticalType, // 统计类型
    };
  },
  beforeCreate() {
    that = this;
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //如果是  新增  移除缓存中的值
    if (this.$route.query.action === "add") {
      window.localStorage.clear();
      this.$store.state.tempImport.basicRelationDatas = [];
      this.$store.state.tempImport.virtualRelationDatas = [];
    }
    this.tepInfoObjRep = this.tepInfoObj;
    this.gatawayTempIdRep = this.gatawayTempId;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.fetchData();
  },
  //方法集合
  methods: {
    async fetchData() {
      if (this.gatawayTempIdRep) {
        let _this = this;
        this.datePointForm.detailList = [];
        const { data } = await applicationApi.pointsDetails(
          this.gatawayTempIdRep
        );
        this.tepInfoObjRep = {
          name: data.name,
          deviceType: data.deviceType,
          manufacturer: data.manufacturer,
          deviceModel: data.deviceModel,
          driveType: data.driveType,
        };

        //回显拿到所有数据点值，编辑时使用 获取 name  id+basic
        let basicPointList = [];
        let virtualPointList = [];
        //遍历基本点
        if (data.configure && data.configure.basicPointList.length !== 0) {
          data.configure.basicPointList.forEach((item, index) => {
            basicPointList.push({
              id: index,
              name: item.name,
              type: "BASIC",
              groupName: item.name + "#BASIC",
            });
          });
          // 基本点 回显数据放入 vuex 中
          store.dispatch("tempImport/setBasicRelationDatas", basicPointList);
        } else {
          localStorage.getItem("basicPointLists") &&
            JSON.parse(localStorage.getItem("basicPointLists")).forEach(
              (item, index) => {
                basicPointList.push({
                  id: index,
                  name: item.name,
                  type: "BASIC",
                  groupName: item.name + "#BASIC",
                });
              }
            );
          store.dispatch("tempImport/setBasicRelationDatas", basicPointList);
        }

        //遍历虚拟点
        this.configure.basicPointList = data.configure.basicPointList;
        this.configure.virtualPointList = data.configure.virtualPointList;
        this.configure.alertRuleList = data.configure.alertRuleList;

        if (data.configure && data.configure.virtualPointList !== 0) {
          data.configure.virtualPointList.forEach((it, index) => {
            virtualPointList.push({
              id: index,
              name: it.name,
              type: "VIRTUAL",
              groupName: it.name + "#VIRTUAL",
            });

            // 虚拟点 回显数据放入 vuex 中
            store.dispatch(
              "tempImport/setVirtualRelationDatas",
              virtualPointList
            );

            _this.selectList = [...basicPointList, ...virtualPointList];
            let str = it.rawFormula;
            for (const el of _this.selectList) {
              let label = `@[${el.groupName}]@`;
              if (str.indexOf(label) != -1) {
                str =
                  el.type === "BASIC"
                    ? str.replaceAll(label, `【基本点】${el.name}`)
                    : str.replaceAll(label, `【虚拟点】${el.name}`);
              }
            }
            this.datePointForm.detailList.push({
              ...it,
              acquisitionType: it.acquisitionType,
              rawFormula: it.rawFormula,
              rawFormulaName: str,
              raw: str ? str : "",
              action: "view",
              isFormula: false,
              id: data.id,
            });
          });
        } else {
          localStorage.getItem("virtualPointLists") &&
            JSON.parse(localStorage.getItem("virtualPointLists")).forEach(
              (item, index) => {
                basicPointList.push({
                  id: index,
                  name: item.name,
                  type: "VIRTUAL",
                  groupName: item.name + "#VIRTUAL",
                });
              }
            );
          store.dispatch(
            "tempImport/setVirtualRelationDatas",
            virtualPointList
          );
        }
        this.selectList = [...basicPointList, ...virtualPointList];
      }
    },

    /** 添加一行 */
    async handleAddRow() {
      this.selectList = [];
      this.selectList = [...this.basicList, ...this.virList];
      this.datePointForm.detailList.push({
        action: "",
        sort: 100,
        isFormula: false,
      });
      setTimeout(() => {
        this.$refs.pointData.bodyWrapper.scrollTop =
          this.$refs.pointData.bodyWrapper.scrollHeight + 50;
      }, 300);
    },
    /** 数据编辑 */
    async handleEditData(row) {
      row.action = "edit";
      const data = this.selectList;
      this.selectList = data.filter((el) => {
        return el.name != row.name;
      });
    },

    //  虚拟点 公式输入框
    handleInput(val) {
      let str = val.rawFormulaName.substring(val.rawFormulaName.length - 2);
      val.raw = val.rawFormulaName;
      if (str === " @") {
        val.isFormula = true;
      } else {
        val.isFormula = false;
      }
    },

    /** 虚拟点 公式选择 */
    hansleSelect(val, obj) {
      val.isFormula = false;
      let s =
        val.rawFormulaName.replace(
          " @",
          `${obj.type === "BASIC" ? "【基本点】" : "【虚拟点】"}`
        ) + obj.name;
      val.rawFormulaName = s;
      val.raw = s;
    },

    /** 删除数据 */
    handleDelData(row, index) {
      this.$confirm("是否确认删除？", "删除", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        // 删除 change
        const configure = this.configure;
        const blists = [];
        if (this.configure.virtualPointList.length !== 0) {
          this.configure.virtualPointList.forEach((item, indexs) => {
            if (index !== indexs) {
              blists.push(item);
            }
          });
        }
        this.configure.virtualPointList = blists;
        store.dispatch(
          "tempImport/setVirtualPointLists",
          this.configure.virtualPointList
        );
        const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
        applicationApi
          .pointsEdit(this.gatawayTempIdRep, concatForm)
          .then((r) => {
            this.$message.success("删除成功!");
            this.gatawayTempIdRep = r.data.id;
            this.fetchData();
          });
      });
    },

    /** 表格中的取消 */
    handleCancel(row, index) {
      this.$alert("填写内容未保存，是否确认取消?", "取消", {
        cancelButtonClass: "btn-custom-cancel",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action === "confirm") {
            if (row.id) {
              // 编辑的取消
              this.resetField("virtualPointForm", index);
              row.action = "view";
            } else {
              this.datePointForm.detailList.splice(index, 1);
            }
          }
        },
      });
    },

    //对部分表单字段进行重置
    resetField(form, index) {
      this.$refs[form].fields.forEach((item) => {
        if (item.prop.split(".")[1] == index) {
          item.resetField();
        }
      });
    },

    handleSaveData(row, index) {
      // 往数组中累加存值
      const Virtual_Data = JSON.parse(
        localStorage.getItem("virtualRelationData")
      );
      if (Virtual_Data == null) {
        const virtualRelationData = [];
        virtualRelationData.push({
          id: index,
          name: row.name,
          type: "VIRTUAL",
        });
        window.localStorage.setItem(
          "virtualRelationData",
          JSON.stringify(virtualRelationData)
        );
        store.dispatch(
          "tempImport/setVirtualRelationDatas",
          virtualRelationData
        );
      } else {
        Virtual_Data.push({
          id: index,
          name: row.name,
          type: "VIRTUAL",
        });
        window.localStorage.setItem(
          "virtualRelationData",
          JSON.stringify(Virtual_Data)
        );
        store.dispatch("tempImport/setVirtualRelationDatas", Virtual_Data);
      }

      if (!this.validateField("virtualPointForm", index)) return;
      const configure = this.configure;
      const blists = [];
      if (this.configure.virtualPointList.length !== 0) {
        this.configure.virtualPointList.forEach((item, indexs) => {
          if (index !== indexs) {
            blists.push(item);
          }
        });
      }
      let str = row.rawFormulaName;
      for (const it of this.selectList) {
        // 基本点
        let label = `${it.type === "BASIC" ? "【基本点】" : "【虚拟点】"}${
          it.name
        }`;
        if (str && str.indexOf(label) != -1) {
          str =
            it.type === "BASIC"
              ? str.replaceAll(label, `@[${it.name}#BASIC]@`)
              : str.replaceAll(label, `@[${it.name}#VIRTUAL]@`);
        }
      }
      row.rawFormula = str;
      blists.push(row);
      this.configure.virtualPointList = blists;
      const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
      // if (this.gatawayTempIdRep) {
      // 编辑/保存共用接口
      applicationApi.pointsEdit(this.gatawayTempIdRep, concatForm).then((r) => {
        if (r.data.configure.virtualPointList.length !== 0) {
          this.virtualPointLists = r.data.configure.virtualPointList;
          store.dispatch(
            "tempImport/setVirtualPointLists",
            this.virtualPointLists
          );
          window.localStorage.setItem(
            "virtualPointLists",
            JSON.stringify(this.virtualPointLists)
          );
        }
        this.$message.success("保存成功");
        this.gatawayTempIdRep = r.data.id;
        this.fetchData();
      });
      // } else {

      // }
    },
    validateField(row, index) {
      let result = true;
      for (let item of this.$refs["virtualPointForm"].fields) {
        if (Number(item.prop.split(".")[1]) == index) {
          this.$refs["virtualPointForm"].validateField(item.prop, (error) => {
            if (error != "") {
              result = false;
            }
          });
        }
        if (!result) break;
      }
      return result;
    },
  },
  computed: {
    ...mapState({
      basicRelationDatas: (state) => state.tempImport.basicRelationDatas,
      virtualRelationDatas: (state) => state.tempImport.virtualRelationDatas,
    }),
  },
  watch: {
    basicRelationDatas: {
      immediate: true,
      deep: true,
      handler(Val) {
        this.basicList = Val;
      },
    },
    virtualRelationDatas: {
      immediate: true,
      deep: true,
      handler(Val) {
        this.virList = Val;
      },
    },
    "datePointForm.detailList": {
      handler(val) {
        if (val.length > 0) {
          val.forEach((it) => {
            if (!it.id) {
              this.btnDisabled = true;
              return;
            } else {
              this.btnDisabled = false;
            }
          });
        } else {
          this.btnDisabled = false;
        }
      },
    },
    configId: {
      handler(val) {
        if (val !== -1) {
          this.fetchData();
        } else {
          this.btnDisabled = true;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep.bg_table .el-table__fixed::before {
  height: 0;
}
::v-deep.el-table {
  td > .cell {
    position: relative;
    overflow: visible;

    .formula_list {
      // position: absolute;
      padding-left: 0 !important;
      background: #ffffff;
      left: 10px;
      top: 30px;
      width: 100%;
      z-index: 9999;
      max-height: 150px;
      overflow-y: auto;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      li {
        list-style: none;
        padding-left: 10px;
        height: 25px;
        line-height: 25px;
        cursor: default;

        &:hover {
          background: #0068ff;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
