<template>
  <div>
    <div class="page_title">{{ titleStates }}-数据配置</div>
    <table-item v-if='driveType===0'  :tepInfoObj="tepInfoObj" :gatawayTempId="gatawayTempId"></table-item>
    <small-table v-if='driveType===17'  :tepInfoObj="tepInfoObj" :gatawayTempId="gatawayTempId"></small-table>
    <point-item :tepInfoObj="tepInfoObj" :gatawayTempId="gatawayTempId"></point-item>
    <alarm-item :tepInfoObj="tepInfoObj" :gatawayTempId="gatawayTempId"></alarm-item>
  </div>
</template>
<script>
import TableItem from './TemplateManage/TableItem.vue';
import PointItem from './TemplateManage/PointItem.vue'
import AlarmItem from './TemplateManage/AlarmItem.vue';
import SmallTable from './TemplateManage/SmallTable.vue';
export default {
  props:{
    templateInfo:{
      type: Object,
    }
  },
  components:{TableItem, PointItem, AlarmItem, SmallTable},
  data() {
    return {
      titleStates: "",
      templateInfoObj:{},
      tepInfoObj:this.$route.query.templateInfos,
      gatawayTempId:Number(this.$route.query.id),  
      driveType:Number(this.$route.query.driveType),
    };
  },
  created(){},
  mounted() {
    this.templateInfoObj=this.templateInfo;
    switch (this.$route.query.action) {
      case "add":
        this.titleStates = "新增模板";
        break;
      default:
        this.titleStates = "编辑模板";
    }
  },
};
</script>

<style>
</style>