var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-view-customer"},[_c('q-page',{attrs:{"schema":{
      xType: 'q-page',
      title: _vm.title,
      items: [
        {
          id: "q-staff-form",
          labelWidth: '150px',
          disabled: _vm.$route.query.mode === 'view',
          xType: 'q-form',
          items: [
            {
              xType: 'q-field',
              fType: 'q-text',
              name: '内部用户姓名',
              label: '内部用户姓名',
              placeholder: '请输入内部用户姓名',
              prop: 'name',
              max: 50,
              rules: [
                {
                  required: true,
                  message: '内部用户姓名不能为空',
                  trigger: 'blur',
                } ],
            },
            {
              xType: 'q-field',
              fType: 'q-select',
              name: '角色',
              label: '角色',
              prop: 'roleId',
              placeholder: '请选择角色',
              remoteOnce: _vm.roleApi.getAll,
              rules: [
                {
                  required: true,
                  message: '角色不能为空',
                  trigger: 'blur',
                } ],
            },
            {
              xType: 'q-field',
              fType: 'q-text',
              name: '手机号',
              label: '手机号',
              prop: 'phone',
              placeholder: '请输入手机号',
              rules: [
                {
                  required: true,
                  message: '手机号不能为空',
                  trigger: 'blur',
                },
                {
                  pattern: _vm.regexs.mobile,
                  message: '请输入正确的手机号',
                  trigger: 'change',
                } ],
            },
            {
              xType: 'q-field',
              fType: 'q-text',
              name: '邮箱',
              label: '邮箱',
              prop: 'email',
              placeholder: '请输入员工邮箱',
              rules: [
                {
                  type: 'email',
                  message: '请输入正确的邮箱地址',
                  trigger: 'change',
                } ],
            },
            {
              xType: 'q-field',
              fType: 'q-select',
              name: '性别',
              label: '性别',
              prop: 'gender',
              placeholder: '请选择性别',
              opts: [
                {
                  label: '男',
                  value: 1,
                },
                {
                  label: '女',
                  value: 2,
                } ],
            },
            {
              xType: 'q-field',
              fType: 'q-location-cascader',
              name: '通讯地址',
              label: '通讯地址',
              prop: 'location',
              placeholder: '请选择省份、市、县/区',
            },
            {
              xType: 'q-field',
              fType: 'q-address',
              name: '',
              label: '',
              prop: 'address',
            } ],
        },
        {
          xType: 'q-button-panel',
          items: [
            {
              xType: 'q-button',
              text: '确定',
              type: 'primary',
              visible: _vm.$route.query.mode !== 'view',
              click: function (event) {
                this$1.ok();
              },
            },
            {
              xType: 'q-button',
              text: '取消',
              click: function () {
                this$1.$back();
              },
            } ],
        } ],
    },"path":"/"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }