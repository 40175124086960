<template>
  <div class="q-application">
    <q-crud ref="crud" :schema="schema" />
  </div>
</template>

<script>
import api from "@/api/application";
// @ is an alias to /src

export default {
  name: "QApplication",
  data() {
    return {
      schema: {
        xType: "q-crud",
        api: api,
        subjectKey: "q-application", //关键字英文
        subjectName: "应用", //项目名中文
        searchFields: [],
        enableAdd: false,
        pagingEnable: false,
        gridColumns: [
          {
            xType: "q-table-column",
            label: "应用名称",
            prop: "name",
          },
          {
            xType: "q-table-column",
            label: "简介",
            prop: "description",
          },
          {
            xType: "q-table-column",
            label: "操作",
            prop: "address",
            // eslint-disable-next-line no-unused-vars
            tpl: (row) => {
              //这里空下来做自定义渲染
              return [
                {
                  xType: "q-button",
                  text: "查看",
                  type: "text",
                  auth: "APPLICATION:GET",
                  click: () => {
                    console.log(row);
                    this.$router.push({
                      name: "EditApplication",
                      query: {
                        appId: row.appId,
                        mode: "view",
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  text: "编辑",
                  type: "text",
                  auth: "APPLICATION:UPDATE",
                  click: () => {
                    console.log(row);
                    this.$router.push({
                      name: "EditApplication",
                      query: {
                        appId: row.appId,
                        mode: "edit",
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  text: "菜单管理",
                  auth:'APPLICATION:RESOURCE',
                  type: "text",
                  click: () => {
                    this.$router.push({
                      name: "Resource",
                      query: {
                        appId: row.appId,
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  text: "模板管理",
                  auth:'APPLICATION:RESOURCE',
                  visible:row.id===2,
                  type: "text",
                  click: () => {
                    console.log(row,"123");
                    this.$router.push({
                      name: "TemplateManage",
                      query: {
                        appId: row.appId,
                      },
                    });
                  },
                },
              ];
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
