<template>
  <div class="q-view-customer">
    <q-page
      :schema="{
        xType: 'q-page',
        title: title,
        items: [
          {
            id: `q-staff-form`,
            labelWidth: '150px',
            disabled: $route.query.mode === 'view',
            xType: 'q-form',
            items: [
              {
                xType: 'q-field',
                fType: 'q-text',
                name: '内部用户姓名',
                label: '内部用户姓名',
                placeholder: '请输入内部用户姓名',
                prop: 'name',
                max: 50,
                rules: [
                  {
                    required: true,
                    message: '内部用户姓名不能为空',
                    trigger: 'blur',
                  },
                ],
              },
              {
                xType: 'q-field',
                fType: 'q-select',
                name: '角色',
                label: '角色',
                prop: 'roleId',
                placeholder: '请选择角色',
                remoteOnce: roleApi.getAll,
                rules: [
                  {
                    required: true,
                    message: '角色不能为空',
                    trigger: 'blur',
                  },
                ],
              },
              {
                xType: 'q-field',
                fType: 'q-text',
                name: '手机号',
                label: '手机号',
                prop: 'phone',
                placeholder: '请输入手机号',
                rules: [
                  {
                    required: true,
                    message: '手机号不能为空',
                    trigger: 'blur',
                  },
                  {
                    pattern: regexs.mobile,
                    message: '请输入正确的手机号',
                    trigger: 'change',
                  },
                ],
              },
              {
                xType: 'q-field',
                fType: 'q-text',
                name: '邮箱',
                label: '邮箱',
                prop: 'email',
                placeholder: '请输入员工邮箱',
                rules: [
                  {
                    type: 'email',
                    message: '请输入正确的邮箱地址',
                    trigger: 'change',
                  },
                ],
              },
              {
                xType: 'q-field',
                fType: 'q-select',
                name: '性别',
                label: '性别',
                prop: 'gender',
                placeholder: '请选择性别',
                opts: [
                  {
                    label: '男',
                    value: 1,
                  },
                  {
                    label: '女',
                    value: 2,
                  },
                ],
              },
              {
                xType: 'q-field',
                fType: 'q-location-cascader',
                name: '通讯地址',
                label: '通讯地址',
                prop: 'location',
                placeholder: '请选择省份、市、县/区',
              },
              {
                xType: 'q-field',
                fType: 'q-address',
                name: '',
                label: '',
                prop: 'address',
              },
            ],
          },
          {
            xType: 'q-button-panel',
            items: [
              {
                xType: 'q-button',
                text: '确定',
                type: 'primary',
                visible: $route.query.mode !== 'view',
                click: (event) => {
                  this.ok();
                },
              },
              {
                xType: 'q-button',
                text: '取消',
                click: () => {
                  this.$back();
                },
              },
            ],
          },
        ],
      }"
      path="/"
    ></q-page>
  </div>
</template>

<script>
import api from "@/api/corpUser";
import roleApi from "@/api/corpRole";
import BizCode from "@/utils/BizCode";
import { findComponentById } from "@/utils/index";
import { regexs } from "@/utils/validate";

export default {
  name: "QEditStaff",
  data() {
    return {
      regexs: regexs,
      title: "",
      roleApi: roleApi,
    };
  },
  async mounted() {
    this.title =
      this.$route.query.mode === "view"
        ? "查看内部用户"
        : this.$route.query.mode === "add"
        ? "新增内部用户"
        : "修改内部用户";
    if (
      this.$route.query.mode === "view" ||
      this.$route.query.mode === "update"
    ) {
      const result = await api.detail(this.$route.query.id);
      if (result.code === BizCode.OK) {
        const { data } = result;
        const { province, city, district, role, ...fields } = data;
        console.log(data);
        const frm = findComponentById("q-staff-form", this);
        frm.setData({
          ...fields,
          roleId: role?.id,
          location: [province, city, district],
        });
      }
    }
  },
  methods: {
    async ok() {
      const frm = findComponentById("q-staff-form", this);
      frm.validate(async (valid) => {
        //验证成功
        if (valid) {
          const fields = frm.getData();
          let result = null;
          console.log(fields);
          const { location, ...others } = fields;
          if (this.$route.query.mode === "add") {
            result = await api.add({
              ...others,
              province: location?.[0],
              city: location?.[1],
              district: location?.[2],
            });
          } else {
            result = await api.update(this.$route.query.id, {
              ...others,
              province: location?.[0],
              city: location?.[1],
              district: location?.[2],
            });
          }
          if (result.code === BizCode.OK) {
            this.$back();
          }
        }
      });
    },
  },
};
</script>
