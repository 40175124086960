<template>
  <div class="q-role">
    <q-crud ref="crud" :schema="schema" />
  </div>
</template>

<script>
import api from "@/api/corpRole";
import applicationApi from "@/api/application";
//import BizCode from "@/utils/BizCode";

// @ is an alias to /src

export default {
  name: "QResource",
  data() {
    return {
      schema: {
        xType: "q-crud",
        handlers: {
          loadData: async (frm, row) => {
            frm.setData({
              mode: "update",
              id: row.id,
              name: row.name,
            });
          },
          saveData: async (_frm, mode, id, fields) => {
            let result = null;
            // eslint-disable-next-line no-unused-vars
            const { name, ...others } = fields;
            result = await applicationApi.updateResource(id, {
              name: name,
            });
            return result;
          },
        },
        enableAdd: false,
        api: {
          update: api.update,
          detail: api.detail,
          getList: async () => {
            const table = await applicationApi.getResourceList(
              this.$route.query.appId
            );
            console.log(table, "table");
            return table;
          },
        },
        subjectKey: "q-resource", //关键字英文
        subjectName: "菜单", //项目名中文
        pagingEnable: false,
        searchFields: [],
        gridColumns: [
          {
            xType: "q-table-column",
            label: "菜单名称",
            prop: "name",
          },
          {
            xType: "q-table-column",
            label: "resource",
            prop: "resource",
          },
          {
            xType: "q-table-column",
            label: "操作",
            prop: "address",
            // eslint-disable-next-line no-unused-vars
            tpl: (row) => {
              //这里空下来做自定义渲染
              return [
                {
                  xType: "q-button",
                  auth:'APPLICATION:RESOURCE',
                  text: "编辑",
                  click: (event) => {
                    this.$refs["crud"].update(event, row);
                  },
                },
              ];
            },
          },
        ],
        formFields: [
          {
            xType: "q-field",
            fType: "q-hidden",
            name: "mode",
            label: "mode",
            prop: "mode",
          },
          {
            xType: "q-field",
            fType: "q-hidden",
            name: "id",
            label: "id",
            prop: "id",
          },
          {
            xType: "q-field",
            fType: "q-input",
            name: "菜单名称",
            label: "菜单名称",
            prop: "name",
            rules: [
              {
                required: true,
                message: "菜单名称菜单名称不能为空",
                trigger: "blur",
              },
            ],
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
