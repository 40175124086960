<template>
  <div class="list_table">
    <el-form :model="datePointForm" ref="alarmPointForm">
      <el-table
        class="bg_table position"
        :data="datePointForm.detailList"
        ref="alarmData"
        style="width: 100%"
      >
        <el-table-column label="名称" show-overflow-tooltip fixed>
          <template slot-scope="scope">
            <el-form-item
              :prop="'detailList.' + scope.$index + '.name'"
              :rules="fromaDataRules.name"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.name }}
              </span>
              <el-input
                v-else
                v-model="scope.row.name"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="持续时间">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{
                  `${scope.row.delay !== "" ? scope.row.delay + "毫秒" : ""}`
                }}
              </span>
              <el-input v-else v-model.number="scope.row.delay" maxlength="4">
                <template slot="append">毫秒</template>
              </el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="报警延迟">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{
                  `${
                    scope.row.duration !== "" ? scope.row.duration + "毫秒" : ""
                  }`
                }}
              </span>
              <el-input
                v-else
                v-model.number="scope.row.duration"
                maxlength="4"
              >
                <template slot="append">毫秒</template>
              </el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- 紧急度 -->
        <el-table-column label="紧急度" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="urgency">
              <span v-if="scope.row.action == 'view'">
                {{
                  scope.row.urgency
                    ? Number(scope.row.urgency)
                    : "--" | urgentStatus
                }}
              </span>
              <div class="" v-else>
                <div>
                  <el-select
                    v-model="scope.row.urgency"
                    @change="handleUrgency(scope.row)"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in urgencyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 是否发送短信 -->
        <el-table-column
          prop="item_name"
          label="是否发送短信"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div class="urgency">
              <span v-if="scope.row.action == 'view'">{{
                scope.row.sendSms === true ? "是" : "否"
              }}</span>
              <el-switch
                v-model="scope.row.sendSms"
                active-color="#088EFF"
                v-else
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <!-- 关联点 -->
        <el-table-column label="关联点" width="200px">
          <template slot-scope="scope">
            <div class="urgency">
              <el-tooltip
                v-if="scope.row.action == 'view'"
                class="item"
                effect="dark"
                :content="
                  scope.row.relatedPointKeys
                    | filterRelPointKeys(scope.row.relatedPointKeys)
                "
                placement="top-start"
              >
                <span>{{
                  scope.row.relatedPointKeys
                    | filterRelPointKeys(scope.row.relatedPointKeys)
                }}</span>
              </el-tooltip>

              <div class="" v-else>
                <div
                  class="max-w hide"
                  v-if="
                    scope.row.relatedPointKeys &&
                    scope.row.relatedPointKeys.length > 0
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      scope.row.relatedPointKeys
                        | filterRelPointKeys(scope.row.relatedPointKeys)
                    "
                    placement="top-start"
                  >
                    <span>{{
                      scope.row.relatedPointKeys
                        | filterRelPointKeys(scope.row.relatedPointKeys)
                    }}</span>
                  </el-tooltip>
                </div>

                <el-button
                  type="text"
                  @click="handleSet(scope.$index, scope.row)"
                  v-if="
                    scope.row.relatedPointKeys &&
                    scope.row.relatedPointKeys.length > 0
                  "
                >
                  <i class="el-icon-edit"></i>
                </el-button>

                <el-button
                  v-if="
                    scope.row.relatedPointKeys &&
                    scope.row.relatedPointKeys.length === 0
                  "
                  type="text"
                  @click="handleSet(scope.$index, scope.row)"
                >
                  <i class="el-icon-circle-plus-outline"></i> 新增
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="公式">
          <template slot-scope="scope">
            <el-form-item
              :prop="'detailList.' + scope.$index + '.rawFormulaName'"
              :rules="fromaDataRules.rawFormulaName"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.rawFormulaName }}
              </span>
              <el-popover v-else placement="right" width="400" trigger="focus">
                <div class="input_popover">
                  <p class="popover_title">公式</p>
                  <div class="pl-20 pr-20">
                    <p class="popover_name">1.输入规则</p>
                    <p>【虚拟点】界面输入“空格@”召唤基本点和虚拟点列表。</p>
                    <p>【报警规则】界面输入“空格@”召唤基本点和虚拟点列表。</p>
                    <p class="popover_name">2.运算符</p>
                    <p>
                      <label>【虚拟点】</label>
                      <span>加(+) 减(-) 乘(*) 除(/)</span>
                    </p>
                    <p>
                      <label>【报警规则】</label>
                      <span
                        >逻辑运算符
                        大于（&gt;）小于（&lt;）与（&amp;&amp;）或（||）</span
                      >
                    </p>
                  </div>
                </div>
                <el-input
                  slot="reference"
                  v-model="scope.row.rawFormulaName"
                  @input="handleAlarm(scope.row)"
                  type="textarea"
                  autosize
                  maxlength="300"
                ></el-input>
              </el-popover>
            </el-form-item>
            <ul v-if="scope.row.isFormula" class="formula_list">
              <li
                v-for="(item, index) in selectList"
                :key="index"
                @click="hansleSelectAlarm(scope.row, item)"
              >
                {{ `${item.type === "BASIC" ? "【基本点】" : "【虚拟点】"}`
                }}{{ item.name }}#{{ item.type }}
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-form-item>
              <div v-if="scope.row.action == 'view'">
                <el-button type="text" @click="handleEditData(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleDelData(scope.row, scope.$index)"
                  >删除</el-button
                >
              </div>
              <div v-else>
                <el-button
                  type="text"
                  @click="handleSaveData(scope.row, scope.$index)"
                  >保存</el-button
                >
                <el-button
                  type="text"
                  @click="handleCancel(scope.row, scope.$index)"
                  >取消</el-button
                >
              </div>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="mb-20 mt-10">
      <el-button type="primary" @click="handleAddRow()" :disabled="btnDisabled"
        ><i class="el-icon-plus"></i> 增加一行</el-button
      >
    </div>
    <el-form>
      <el-form-item>
        <el-button type="primary" @click="submitAllPointsDatas()"
          >确定</el-button
        >
        <el-button @click="backToListPage()">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- 关联点弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      class="bg_dialog"
      :before-close="handleClose"
    >
      <div class="dialog_point">
        <div class="point_content">
          <el-row :gutter="20">
            <el-col :span="8" v-for="(item, index) in relation" :key="index">
              <div class="grid">
                <div @click="handleSelect(item)">
                  <div class="icon"><i class="el-icon-menu"></i></div>
                  <span :title="`${item.name}`">
                    {{ item.name }}
                  </span>
                  <span class="select_icon">
                    <i class="el-icon-check" v-if="item.isSelect"></i>
                  </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmitPoint()">确 定</el-button>
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  isMsgStatus,
  urgencyStatus,
  urgentStatus,
} from "@/utils/gatewayFilters";
import { urgencyLists } from "@/utils/gatewayArrays";
import applicationApi from "@/api/application";
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "alarmItem",
  ...mapState({
    basicPointLists: (state) => state.tempImport.basicPointLists,
    virtualPointLists: (state) => state.tempImport.virtualPointLists,
    alertRuleLists: (state) => state.tempImport.alertRuleLists,
    basicRelationDatas: (state) => state.tempImport.basicRelationDatas,
    virtualRelationDatas: (state) => state.tempImport.virtualRelationDatas,
  }),
  //import引入的组件需要注入到对象中才能使用
  filters: {
    isMsgStatus,
  },
  props: {
    configId: Number,
    tepInfoObj: {
      type: Object,
      defalut: {},
    },
    gatawayTempId: Number,
  },
  components: {},
  data() {
    //这里存放数据
    return {
      relationRow: [],
      relationVal: [],
      alertRuleLists: [],
      configure: {
        //配置详情
        basicPointList: [], //基本点
        virtualPointList: [], //虚拟点
        alertRuleList: [], //报警点
      },
      tepInfoObjRep: {},
      gatawayTempIdRep: null,
      title: "",
      alarmRulesList: [], // 列表数据
      btnDisabled: false,
      datePointForm: {
        detailList: [],
      },
      searchForm: {
        page: 1,
        size: 20,
      },
      total: 0,
      fromaDataRules: {
        name: [{ required: true, message: "名称不能为空", trigger: "submit" }],
        rawFormulaName: [
          { required: true, message: "公式不能为空", trigger: "submit" },
        ],
      },
      selectList: [],
      virtualList: [],
      allList: [],
      sequenceList: [
        {
          value: 0,
          label: "从数据点中选择",
        },
      ], // 是否发送短信
      urgencyList: urgencyLists,
      dialogVisible: false,
      relation: [],
      relationSelect: [],
      alarmRulesPoint: [],
      dataPointList: [],
      //基本点+虚拟点关联点
      basicList: [],
      virList: [],
    };
  },
  filters: {
    urgentStatus,
    filterRelPointKeys(row) {
      return row.map((item) => item.name).join("，");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.tepInfoObjRep = this.tepInfoObj;
    this.gatawayTempIdRep = this.gatawayTempId;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.fetchData();
  },
  //方法集合
  methods: {
    fetchData() {
      if (this.gatawayTempIdRep) {
        let _this = this;
        this.datePointForm.detailList = [];
        applicationApi.pointsDetails(this.gatawayTempIdRep).then(({ data }) => {
          this.tepInfoObjRep = {
            name: data.name,
            deviceType: data.deviceType,
            manufacturer: data.manufacturer,
            deviceModel: data.deviceModel,
            driveType: data.driveType,
          };

          //遍历基本点
          let basicPointList = [];
          let virtualPointList = [];
          if (data.configure && data.configure.basicPointList.length !== 0) {
            data.configure.basicPointList.forEach((item, index) => {
              basicPointList.push({
                id: index,
                name: item.name,
                type: "BASIC",
                groupName: item.name + "#BASIC",
              });
            });
          }

          if (data.configure && data.configure.virtualPointList.length !== 0) {
            data.configure.virtualPointList.forEach((item, index) => {
              virtualPointList.push({
                id: index,
                name: item.name,
                type: "VIRTUAL",
                groupName: item.name + "#VIRTUAL",
              });
            });
          }
          this.selectList = [...basicPointList, ...virtualPointList];
          _this.selectList = [...basicPointList, ...virtualPointList];
          //回显拿到所有数据点值，编辑时使用
          this.configure.basicPointList = data.configure.basicPointList; //基本点
          this.configure.virtualPointList = data.configure.virtualPointList;
          this.configure.alertRuleList = data.configure.alertRuleList;

          data.configure.alertRuleList.forEach((it) => {
            let contArr = [];
            JSON.parse(window.localStorage.getItem("relationPoint")).forEach(
              (item) => {
                it.relatedPointKeys.forEach((i) => {
                  if (item.groupName === i) {
                    contArr.push(item);
                  }
                });
              }
            );
            let str = it.rawFormula;
            for (const el of _this.selectList) {
              let label = `@[${el.groupName}]@`;
              if (str.indexOf(label) != -1) {
                str =
                  el.type === "BASIC"
                    ? str.replaceAll(label, `【基本点】${el.name}`)
                    : str.replaceAll(label, `【虚拟点】${el.name}`);
              }
            }

            this.datePointForm.detailList.push({
              //报警列表数据
              ...it,
              id: data.id,
              rawFormulaName: str ? str : "",
              raw: str ? str : "",
              rawFormula: it.rawFormula,
              delay: it.delay,
              duration: it.duration,
              action: "view",
              // name: it.id,
              name: it.name,
              item_name: it.name,
              select: false,
              urgency: it.params.urgency,
              sendSms: it.params.sendSms,
              relatedPointKeys: contArr,
            });
            this.alarmRulesPoint = this.datePointForm.detailList;
          });
        });
      }
    },

    /** 添加一行 */
    async handleAddRow() {
      this.datePointForm.detailList.push({
        action: "",
        isFormula: false,
        isPoint: false,
        rawFor: "",
        delay: 5,
        duration: 0,
        urgency: "", //紧急度
        sendSms: false, //是否发送短信
        relatedPointKeys: [], //关联点
      });
      this.selectList = [...this.basicList, ...this.virList];
      setTimeout(() => {
        this.$refs.alarmData.bodyWrapper.scrollTop =
          this.$refs.alarmData.bodyWrapper.scrollHeight + 50;
      }, 300);
    },
    /** 保存 */
    // eslint-disable-next-line no-unused-vars
    handleSaveData(row, index) {
      if (!this.validateField("alarmPointForm", index)) return;
      const configure = this.configure;
      const blists = [];
      if (this.configure.alertRuleList.length !== 0) {
        this.configure.alertRuleList.forEach((item, indexs) => {
          if (index !== indexs) {
            blists.push(item);
          }
        });
      }

      let str = row.rawFormulaName;
      for (const it of this.selectList) {
        // 基本点
        let label = `${it.type === "BASIC" ? "【基本点】" : "【虚拟点】"}${
          it.name
        }`;
        if (str && str.indexOf(label) != -1) {
          str =
            it.type === "BASIC"
              ? str.replaceAll(label, `@[${it.name}#BASIC]@`)
              : str.replaceAll(label, `@[${it.name}#VIRTUAL]@`);
        }
      }
      row.rawFormula = str;
      row.select = false;
      row.params = {
        urgency: row.urgency, //紧急度
        sendSms: row.sendSms, //是否发送短信
      };
      row.relatedPointKeys = row.relatedPointKeys.map((item) => item.groupName);
      blists.push(row);
      this.configure.alertRuleList = blists;
      const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
      // 新增、编辑  （共用更新接口）

      applicationApi.pointsEdit(this.gatawayTempIdRep, concatForm).then((r) => {
        if (r.data.configure.alertRuleList.length !== 0) {
          this.alertRuleLists = r.data.configure.alertRuleList;
          store.dispatch(
            "tempImport/setAlertRuleListsLists",
            this.alertRuleLists
          );
          window.localStorage.setItem(
            "alertRuleLists",
            JSON.stringify(this.alertRuleLists)
          );
        }
        this.$message.success("保存成功");
        this.gatawayTempIdRep = r.data.id;
        this.fetchData();
      });
    },
    validateField(row, index) {
      let result = true;
      for (let item of this.$refs["alarmPointForm"].fields) {
        if (Number(item.prop.split(".")[1]) == index) {
          this.$refs["alarmPointForm"].validateField(item.prop, (error) => {
            if (error != "") {
              result = false;
            }
          });
        }
        if (!result) break;
      }
      return result;
    },

    /** 删除数据 */
    handleDelData(row, index) {
      this.$confirm("是否确认删除？", "删除", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const configure = this.configure;
        const blists = [];
        if (this.configure.alertRuleList.length !== 0) {
          this.configure.alertRuleList.forEach((item, indexs) => {
            if (index !== indexs) {
              blists.push(item);
            }
          });
        }
        this.configure.alertRuleList = blists;
        store.dispatch(
          "tempImport/setAlertRuleListsLists",
          this.configure.alertRuleList
        );
        const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
        applicationApi
          .pointsEdit(this.gatawayTempIdRep, concatForm)
          .then((r) => {
            this.$message.success("删除成功!");
            this.gatawayTempIdRep = r.data.id;
            this.fetchData();
          });
      });
    },
    /** 表格中的取消 */
    handleCancel(row, index) {
      this.$alert("填写内容未保存，是否确认取消?", "取消", {
        cancelButtonClass: "btn-custom-cancel",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action === "confirm") {
            if (row.id) {
              // 编辑的取消
              this.resetField("alarmPointForm", index);
              row.action = "view";
            } else {
              this.datePointForm.detailList.splice(index, 1);
            }
          }
        },
      });
    },
    //对部分表单字段进行重置
    resetField(form, index) {
      this.$refs[form].fields.forEach((item) => {
        if (item.prop.split(".")[1] == index) {
          item.resetField();
        }
      });
    },
    /** 报警规则 公式输入框 */
    handleAlarm(val) {
      let str = val.rawFormulaName.substring(val.rawFormulaName.length - 2);
      val.raw = val.rawFormulaName;
      if (str === " @") {
        val.isFormula = true;
      } else {
        val.isFormula = false;
      }
    },

    /** 基本点 公式选择 */
    hansleSelectAlarm(val, obj) {
      val.isFormula = false;
      let s =
        val.rawFormulaName.replace(
          " @",
          `${obj.type === "BASIC" ? "【基本点】" : "【虚拟点】"}`
        ) + obj.name;
      val.rawFormulaName = s;
      val.raw = s;
    },

    /** 虚拟点 公式选择 */
    hansleSelectVirtual(val, obj) {
      val.isPoint = false;
      let s = val.rawFormulaName.replace(" $", "[虚拟点]") + obj.name;
      val.rawFormulaName = s;
      val.raw = s;
    },

    /** 数据编辑 */
    async handleEditData(row) {
      row.action = "edit";
      this.selectList = this.selectList.filter((el) => {
        return el.name != row.name;
      });
    },
    //返回列表页
    backToListPage() {
      this.$router.push({
        name: "TemplateManage",
      });
    },

    /** 设置关联点 */
    handleSet(index, row) {
      this.relation = [];
      this.relationSelect = [];
      this.rowIndex = index;
      let arr = [];
      arr = row.relatedPointKeys.map((it) => {
        return it.name;
        // return it;
      });

      row.relatedPointKeys.forEach((i) => {
        this.relationSelect.push({ ...i });
      });

      this.selectList = [...this.basicList, ...this.virList];
      this.selectList.forEach((it) => {
        this.relation.push({
          ...it,
          item_name: it.name,
          isSelect: arr.indexOf(it.name) !== -1 ? true : false,
        });
      });
      window.localStorage.setItem("relationPoint", "");
      window.localStorage.setItem("relationPoint", JSON.stringify(this.relation) );
      this.dialogVisible = true;
    },
    /** 选择数据点 */
    handleSelect(row) {
      row.isSelect = !row.isSelect;
      if (row.isSelect === true) {
        this.relationSelect.push(row);
      } else {
        this.relationSelect.forEach((it, index) => {
          if (it.item_name === row.item_name) {
            this.relationSelect.splice(index, 1);
          }
        });
      }
    },
    /** 弹窗确定关联点 */
    handleSubmitPoint() {
      this.datePointForm.detailList[this.rowIndex].relatedPointKeys =
        this.relationSelect;
      this.handleClose();
    },
    /** 关闭弹框 */
    handleClose() {
      this.relation = [];
      this.relationSelect = [];
      this.dialogVisible = false;
    },
    //紧急点
    handleUrgency(row) {
      row.select = !row.select;
    },
    //确定 提交所有数据点数据
    submitAllPointsDatas() {
      //  清空基本点值 basicRelationData
      localStorage.removeItem("basicRelationData");
      localStorage.removeItem("virtualRelationData");
      localStorage.removeItem("basicPointLists");
      localStorage.removeItem("virtualPointLists");
      localStorage.removeItem("alertRuleLists");

      const configure = this.configure;
      const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
      applicationApi.pointsEdit(this.gatawayTempIdRep, concatForm).then((r) => {
        this.$message.success("保存成功");
        setTimeout(() => {
          this.$router.push({ name: "TemplateManage" });
          //  清空 vuex 中的值
          window.location.reload();
        }, 300);
      });
    },
  },
  computed: {
    watchTemp() {
      return this.$store.state.tempImport.basicPointLists;
    },
    watchV() {
      return this.$store.state.tempImport.virtualPointLists;
    },
    watchA() {
      return this.$store.state.tempImport.alertRuleLists;
    },
    //基本点+虚拟点
    watchBasic() {
      return this.$store.state.tempImport.basicRelationDatas;
    },
    watchVirtual() {
      return this.$store.state.tempImport.virtualRelationDatas;
    },
  },
  watch: {
    watchBasic: {
      immediate: true,
      handler(Val) {
        this.basicList = Val;
      },
    },
    watchVirtual: {
      immediate: true,
      handler(Val) {
        this.virList = Val;
      },
    },
    watchTemp: {
      immediate: true,
      handler(Val) {
        this.configure.basicPointList = Val;
      },
    },
    watchV: {
      immediate: true,
      handler(Val) {
        this.configure.virtualPointList = Val;
      },
    },
    watchA: {
      immediate: true,
      handler(Val) {
        this.configure.alertRuleList = Val;
      },
    },
    "datePointForm.detailList": {
      handler(val) {
        if (val.length > 0) {
          val.forEach((it) => {
            if (!it.id) {
              this.btnDisabled = true;
              return;
            } else {
              this.btnDisabled = false;
            }
          });
        } else {
          this.btnDisabled = false;
        }
      },
    },
    configId: {
      handler(val) {
        if (val !== -1) {
          this.fetchData();
        } else {
          this.btnDisabled = true;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .bg_dialog .el-dialog__headerbtn {
  top: 8px !important;
}
::v-deep.bg_table .el-table__fixed::before {
  height: 0;
}
td > .cell {
  // position: relative;
  // overflow: visible;

  .formula_list {
    padding-left: 0;
    // position: absolute;
    background: #ffffff;
    left: 10;
    top: 40px;
    width: 100%;
    z-index: 9999;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    li {
      list-style: none;
      padding-left: 10px;
      height: 25px;
      line-height: 25px;
      cursor: default;

      &:hover {
        background: #0068ff;
        color: #ffffff;
      }
    }
  }
}
::v-deep.el-icon-menu {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 5px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #9599a0;
  vertical-align: middle;
}
.point_content,
.select_content {
  width: 100%;

  &.device_content {
    margin: 20px 0;
  }

  .select_device_point {
    width: 55%;
    margin: 20px auto;
  }
  .grid {
    text-align: center;

    & > div {
      display: inline-block;
      width: 200px;
      height: 40px;
      line-height: 40px;
      text-align: left;
      border: 1px solid #3a5987;
      border-radius: 4px;
      padding: 0 20px;
      color: #ffffff;
      margin-bottom: 20px;
      cursor: default;

      &.select_grid {
        background: linear-gradient(
          0deg,
          #00d5ff 0%,
          rgba(0, 213, 255, 0.1) 100%
        );
      }

      div.icon {
        width: 24px;
        height: 24px;
        // margin-top: 8px;
        // background: rgba(0, 213, 255, 0.2);
        display: inline-block;
        margin-right: 10px;
        border-radius: 5px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        color: #9599a0;
      }

      span {
        font-size: 12px;
        display: inline-block;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
        color: #9599a0;
        height: 40px;
        line-height: 40px;
      }

      //选中未选中的样式
      .select_icon {
        display: inline-block;
        width: 14px;
        height: 14px;
        border: 1px solid #3a5987;
        // border-radius: 50%;
        float: right;
        margin-top: 12px;
        position: relative;

        &.icon_active {
          background: #3a5987;
        }

        i {
          position: absolute;
          left: 0;
          top: 2px;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
}
.urgency {
  margin-bottom: 20px !important;
}
</style>
