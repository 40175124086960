var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-customer"},[_c('q-crud',{ref:"crud",attrs:{"schema":_vm.schema}}),_c('el-dialog',{attrs:{"modal":true,"close-on-click-modal":false,"destroy-on-close":true,"title":"订阅","visible":_vm.dialogVisible},on:{"close":function($event){_vm.radioboxes = []},"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('q-form',{ref:"q-subcription-form",attrs:{"path":"/q-subcription-form","schema":{
        xType: 'q-form',
        items: [
          {
            xType: 'q-field',
            fType: 'q-hidden',
            name: 'id',
            label: 'id',
            prop: 'id',
          } ].concat( this.radioboxes ),
      }}})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }