/** @description 获取所属行业 */
export function getIndustry() {
  const location = require('@/assets/json/Industry.json')
  let data = formatLocation(location.districts)
  return getTreeData(data)
}

// 递归判断列表，把最后的children设为undefined，解决最后一列空白问题
export function getTreeData(data) {
  data.forEach((r) => {
    if (r.children.length < 1) {
      r.children = undefined
    } else {
      getTreeData(r.children)
    }
  })
  return data
}

export function formatLocation(locationArr) {
  const arr = []
  let obj = {}
  locationArr.forEach(item => {
    const tmp = { ...item, children: [] }
    if (tmp.districts) {
      tmp.children = formatLocation(tmp.districts)
      obj = {
        value: tmp.adcode ? tmp.adcode : tmp.id,
        label: tmp.name,
        children: tmp.children
      }
    }
    arr.push(obj)
  })
  return arr
}

/**
 *  函数防抖
 *  @param {Function} func  包装的函数
 *  @param {num} delay      延迟时间
 *  @param {boolean} immediate 第一次滚动会执行两次  开始滚动和结束滚动的时候
 *  @return {*}      
 */
export function debounce(func, delay, immediate = false) {
  let timer, context = this
  return (...args) => {
    if (immediate) {
      func.apply(context, args)
      immediate = false
      return
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}

export function getP(arr) { // 获取省
  let testArr = [];
  let resultArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (testArr.indexOf(arr[i].province) === -1) {
      resultArr.push({
        province: arr[i].province,
        coordinate: [arr[i].longitude, arr[i].latitude],
        origin: [arr[i]],
      });
      testArr.push(arr[i].province);
    } else {
      for (let j = 0; j < resultArr.length; j++) {
        if (resultArr[j].province == arr[i].province) {
          resultArr[j].origin.push(arr[i]);
          break;
        }
      }
    }
  }
  return resultArr;
}
export function getC(arr) { // 获取市
  let testArr = [];
  let resultArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (testArr.indexOf(arr[i].city) === -1) {
      resultArr.push({
        city: arr[i].city,
        coordinate: [arr[i].longitude, arr[i].latitude],
        origin: [arr[i]],
      });
      testArr.push(arr[i].city);
    } else {
      for (let j = 0; j < resultArr.length; j++) {
        if (resultArr[j].city == arr[i].city) {
          resultArr[j].origin.push(arr[i]);
          break;
        }
      }
    }
  }
  return resultArr;
}
export function getD(arr) { // 获取区
  let testArr = [];
  let resultArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (testArr.indexOf(arr[i].district) === -1) {
      resultArr.push({
        district: arr[i].district,
        coordinate: [arr[i].longitude, arr[i].latitude],
        origin: [arr[i]],
      });
      testArr.push(arr[i].district);
    } else {
      for (let j = 0; j < resultArr.length; j++) {
        if (resultArr[j].district == arr[i].district) {
          resultArr[j].origin.push(arr[i]);
          break;
        }
      }
    }
  }
  return resultArr;
}