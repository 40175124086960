var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-view-customer"},[_c('q-page',{attrs:{"schema":{
				xType: 'q-page',
				title: '修改应用',
				items: [
					{
						id: "q-application-form",
						labelWidth: '150px',
						disabled: _vm.$route.query.mode === 'view',
						xType: 'q-form',
						items: [
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '应用名称',
								label: '应用名称',
								prop: 'name',
								rules: [
									{
										required: true,
										message: '应用名称不能为空',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-image-upload',
								name: '展示图片',
								label: '展示图片',
								prop: 'logo',
                size: 50,
								rules: [
									{
										required: true,
										message: '展示图片不能为空',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '简介',
								label: '简介',
								prop: 'description',
								rules: [
									{
										required: true,
										message: '简介不能为空',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-version-list',
								name: '版本信息',
								label: '版本信息',
								prop: 'releaseNews',
								rules: [
									{
										required: true,
										message: '版本信息不能为空',
										trigger: 'blur',
									} ],
							} ],
					},
					{
            xType: 'q-button-panel',
            items: [
              {
                xType: 'q-button',
                text: '确定',
                type: 'primary',
                visible: _vm.$route.query.mode !== 'view',
                click: function (event) {
                  this$1.ok();
                },
              },
              {
                xType: 'q-button',
                text: '取消',
                click: function () {
                  this$1.$back();
                },
              } ],
          } ],
			},"path":"/"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }