<template>
	<div class="q-view-customer">
		<q-page
			:schema="{
				xType: 'q-page',
				title: '修改应用',
				items: [
					{
						id: `q-application-form`,
						labelWidth: '150px',
						disabled: $route.query.mode === 'view',
						xType: 'q-form',
						items: [
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '应用名称',
								label: '应用名称',
								prop: 'name',
								rules: [
									{
										required: true,
										message: '应用名称不能为空',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-image-upload',
								name: '展示图片',
								label: '展示图片',
								prop: 'logo',
                size: 50,
								rules: [
									{
										required: true,
										message: '展示图片不能为空',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '简介',
								label: '简介',
								prop: 'description',
								rules: [
									{
										required: true,
										message: '简介不能为空',
										trigger: 'blur',
									},
								],
							},
							{
								xType: 'q-field',
								fType: 'q-version-list',
								name: '版本信息',
								label: '版本信息',
								prop: 'releaseNews',
								rules: [
									{
										required: true,
										message: '版本信息不能为空',
										trigger: 'blur',
									},
								],
							},
						],
					},
					{
            xType: 'q-button-panel',
            items: [
              {
                xType: 'q-button',
                text: '确定',
                type: 'primary',
                visible: $route.query.mode !== 'view',
                click: (event) => {
                  this.ok();
                },
              },
              {
                xType: 'q-button',
                text: '取消',
                click: () => {
                  this.$back();
                },
              },
            ],
          },
				],
			}"
			path="/"
		>
		</q-page>
	</div>
</template>

<script>
import api from '@/api/application';
import BizCode from '@/utils/BizCode';
import { findComponentById } from '@/utils/index';
export default {
	name: 'QEditApplication',
	data() {
		return {};
	},

	async mounted() {
		console.log(this.$route.query.mode);
		const result = await api.detail(this.$route.query.appId);
		console.log(result,"application编辑页面");
		if (result.code === BizCode.OK) {
			const { data } = result;
			const {
				id,
				appId,
				name,
				logo,
				description,
				linkUrl,
				releaseNews,
				// eslint-disable-next-line no-unused-vars
				...fields
			} = data;
			let versionList = null;
			if (releaseNews === undefined || releaseNews === null) {
				versionList = [
					{
						id: this.$options.filters._uuid(),
						date: '',
						versionNumber: '',
						content: '',
					},
				];
			} else if (releaseNews.length === 0) {
				versionList = [
					{
						id: this.$options.filters._uuid(),
						date: '',
						versionNumber: '',
						content: '',
					},
				];
			} else {
				versionList = releaseNews;
			}
			const frm = findComponentById('q-application-form', this);
			frm.setData({
				id,
				appId,
				name,
				logo,
				description,
				linkUrl,
				releaseNews: versionList,
			});
		}
	},

	methods: {
		async ok() {
			const frm = findComponentById('q-application-form', this);
			frm.validate(async (valid) => {
				//验证成功
				if (valid) {
					const fields = frm.getData();
					let result = null;
					const {
						appId,
						name,
						logo,
						description,
						// eslint-disable-next-line no-unused-vars
						linkUrl,
						releaseNews,
						// eslint-disable-next-line no-unused-vars
						...others
					} = fields;
					//没有新增,只有修改
					result = await api.update(appId, {
						name,
						logo,
						description,
						releaseNews,
					});
					if (result.code === BizCode.OK) {
						this.$back();
					}
				}
			});
		},
	},
};
</script>
