<template>
  <div class="list_table">
    <el-form :model="datePointForm" ref="datePointForm">
      <el-table
        class="bg_table"
        highlight-current-row
        :data="datePointForm.dataPointList"
        style="width: 100%"
      >
        <el-table-column label="名称">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.name'"
              :rules="fromaDataRules.name"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.name || "" }}
              </span>
              <el-input
                v-else
                v-model="scope.row.name"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="单位">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.description || "" }}
              </span>
              <el-input
                v-else
                v-model="scope.row.description"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="采集类型">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.acquisitionType'"
              :rules="fromaDataRules.acquisitionType"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.acquisitionType || "" | staristicalStatus }}
              </span>
              <el-select
                v-model="scope.row.acquisitionType"
                v-else
                placeholder="请选择采集类型"
              >
                <el-option
                  v-for="item in statisticalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="数据标识">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.dataId'"
              :rules="fromaDataRules.dataId"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.dataId || "0" }}
              </span>
              <el-input
                v-else
                v-model.number="scope.row.dataId"
                maxlength="8"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="k">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.k'"
              :rules="fromaDataRules.k"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.k || "0" }}
              </span>
              <el-input v-else v-model="scope.row.k" maxlength="8"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="b">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.b'"
              :rules="fromaDataRules.b"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.b || "0" }}
              </span>
              <el-input v-else v-model="scope.row.b" maxlength="8"></el-input>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.action == 'view'">
              <el-form-item>
                <el-button type="text" @click="handleEditData(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleDelData(scope.row, scope.$index)"
                  >删除</el-button
                >
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item>
                <el-button
                  type="text"
                  @click="handleSaveData(scope.row, scope.$index)"
                  >保存</el-button
                >
                <el-button
                  type="text"
                  @click="handleCancel(scope.row, scope.$index)"
                  >取消</el-button
                >
              </el-form-item>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="tar mt-15"></div>
    <div class="mb-20 mt-10">
      <el-button type="primary" @click="handleAddRow()" :disabled="btnDisabled"
        ><i class="el-icon-plus"></i> 增加一行</el-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { StatisticalType } from "@/utils/gatewayArrays";
import { staristicalStatus } from "@/utils/gatewayFilters";
import applicationApi from "@/api/application";
import store from "@/store";
export default {
  name: "smallTable",
  props: {
    configId: Number,
    tepInfoObj: {
      type: Object,
      defalut: {},
    },
    gatawayTempId: Number,
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  filters: {
    staristicalStatus,
  },
  data() {
    return {
      tepInfoObjRep: {},
      gatawayTempIdRep: null,
      configure: {
        //配置详情
        basicPointList: [], //基本点
        virtualPointList: [], //虚拟点
        alertRuleList: [], //报警点
      },
      action: "create",
      btnDisabled: false,
      dataPointList: [], // 列表数据
      datePointForm: {
        dataPointList: [],
      },
      statisticalList: StatisticalType, // 统计类型
      fromaDataRules: {
        name: [{ required: true, message: "名称不能为空", trigger: "submit" }],

        acquisitionType: [
          { required: true, message: "请选择采集类型", trigger: "submit" },
        ],
        k: [{ required: true, message: "请输入数字", trigger: "submit" }],
        b: [{ required: true, message: "请输入数字", trigger: "submit" }],
        dataId: [
          { type: "number", message: "请输入数据标识", trigger: "submit" },
        ],
      },
    };
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
     //如果是  新增  移除缓存中的值
    if (this.$route.query.action === "add") {
      window.localStorage.clear(); 
      this.$store.state.tempImport.basicRelationDatas=[];
      this.$store.state.tempImport.virtualRelationDatas=[];
    }
    this.tepInfoObjRep = this.tepInfoObj;
    this.gatawayTempIdRep = this.gatawayTempId;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.fetchData();
  },
  //方法集合
  methods: {
    /** 初始化 */
    async fetchData() {
      this.datePointForm.dataPointList = [];
      // const data = await pointList(this.configId);
      if (this.gatawayTempIdRep) {
        const { data } = await applicationApi.pointsDetails(
          this.gatawayTempIdRep
        );
        this.tepInfoObjRep = {
          name: data.name,
          deviceType: data.deviceType,
          manufacturer: data.manufacturer,
          deviceModel: data.deviceModel,
          driveType: data.driveType,
        };

        if (data.configure) {
          //回显拿到所有数据点值，编辑时使用
          this.configure.basicPointList = data.configure.basicPointList; //基本点
          this.configure.virtualPointList = data.configure.virtualPointList;
          this.configure.alertRuleList = data.configure.alertRuleList;

          data.configure.basicPointList.forEach((it) => {
            this.datePointForm.dataPointList.push({
              ...it,
              acquisitionType:
                it.acquisitionType !== "" ? it.acquisitionType : "",
              k: it.k !== "" ? it.k : "",
              b: it.b !== "" ? it.b : "",
              dataId: it.dataId !== "" ? Number(it.dataId) : "",
              action: "view",
              id: data.id,
            });
          });
        }
      }
    },
    /** 添加一行 */
    handleAddRow() {
      this.datePointForm.dataPointList.push({
        action: "add",
        acquisitionType: 0,
        k: 1,
        b: 0,
        dataId: "",
      });
    },
    /** 数据编辑 */
    handleEditData(row) {
      row.action = "edit";
    },
    /** 数据删除 */
    handleDelData(row, index) {
      this.$confirm("是否确认删除？", "删除", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const configure = this.configure;
        const blists = [];
        if (this.configure.basicPointList.length !== 0) {
          this.configure.basicPointList.forEach((item, indexs) => {
            if (index !== indexs) {
              blists.push(item);
            }
          });
        }
        this.configure.basicPointList = blists;
        store.dispatch(
          "tempImport/setBasicPointLists",
          this.configure.basicPointList
        );
        const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
        applicationApi
          .pointsEdit(this.gatawayTempIdRep, concatForm)
          .then((r) => {
            this.$message.success("删除成功!");
            this.gatawayTempIdRep = r.data.id;
            this.fetchData();
          });
      });
    },
    /** 验证 */
    validateField(row, index) {
      let result = true;
      for (let item of this.$refs["datePointForm"].fields) {
        if (Number(item.prop.split(".")[1]) == index) {
          this.$refs["datePointForm"].validateField(item.prop, (error) => {
            if (error != "") {
              result = false;
            }
          });
        }
        if (!result) break;
      }
      return result;
    },

    handleSaveData(row, index) {
      if (!this.validateField("tableForm", index)) return;
      // 往数组中累加存值
      const Basic_Data = JSON.parse(localStorage.getItem("basicRelationData"));
      if (Basic_Data == null) {
        const basicRelationData = [];
        basicRelationData.push({
          id: index,
          name: row.name,
          type: "BASIC",
          groupName: row.name + "#BASIC",
        });
        window.localStorage.setItem(
          "basicRelationData",
          JSON.stringify(basicRelationData)
        );
        //存缓存，监听最新值， window.addEventListener("storage", function (e) {})触发不了
        store.dispatch("tempImport/setBasicRelationDatas", basicRelationData);
      } else {
        localStorage.removeItem("basicRelationData");
        Basic_Data.push({
          id: index,
          name: row.name,
          type: "BASIC",
          groupName: row.name + "#BASIC",
        });
        window.localStorage.setItem(
          "basicRelationData",
          JSON.stringify(Basic_Data)
        );
        store.dispatch("tempImport/setBasicRelationDatas", Basic_Data);
      }

      const configure = this.configure;
      const blists = [];
      if (this.configure.basicPointList.length !== 0) {
        this.configure.basicPointList.forEach((item, indexs) => {
          if (index !== indexs) {
            blists.push(item);
          }
        });
      }
      blists.push(row);
      this.configure.basicPointList = blists;
      const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
      applicationApi.pointsEdit(this.gatawayTempIdRep, concatForm).then((r) => {
        if (r.data.configure.basicPointList.length !== 0) {
          this.basicPointLists = r.data.configure.basicPointList;
          store.dispatch("tempImport/setBasicPointLists", this.basicPointLists);
          window.localStorage.setItem(
            "basicPointLists",
            JSON.stringify(this.basicPointLists)
          );
        }
        this.$message.success("保存成功");
        this.gatawayTempIdRep = r.data.id;
        this.fetchData();
      });
    },

    /** 表格中的取消 */
    handleCancel(row, index) {
      this.$alert("填写内容未保存，是否确认取消?", "取消", {
        cancelButtonClass: "btn-custom-cancel",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action === "confirm") {
            if (row.id) {
              // 编辑的取消
              this.resetField("datePointForm", index);
              row.action = "view";
            } else {
              this.datePointForm.dataPointList.splice(index, 1);
            }
          }
        },
      });
    },

    //对部分表单字段进行重置
    resetField(form, index) {
      this.$refs[form].fields.forEach((item) => {
        if (item.prop.split(".")[1] == index) {
          item.resetField();
        }
      });
    },
  },
  watch: {
    "datePointForm.dataPointList": {
      handler(val) {
        if (val.length > 0) {
          val.forEach((it) => {
            if (!it.id) {
              this.btnDisabled = true;
              return;
            } else {
              this.btnDisabled = false;
            }
          });
        } else {
          this.btnDisabled = false;
        }
      },
    },

    configId: {
      handler(val) {
        if (val !== -1) {
          this.fetchData();
        } else {
          this.btnDisabled = true;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
</style>
