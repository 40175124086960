<template>
  <div class="list_table">
    <el-form :model="datePointForm" ref="tableForm">
      <el-table
        class="bg_table"
        highlight-current-row
        :data="datePointForm.dataPointList"
        ref="dataList"
        style="width: 100%"
        height="450"
      >
        <el-table-column label="名称" width="150" show-overflow-tooltip fixed>
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.name'"
              :rules="fromaDataRules.name"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.name || "" }}
              </span>
              <el-input
                v-else
                v-model="scope.row.name"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="单位" width="150">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.description || "" }}
              </span>
              <el-input
                v-else
                v-model="scope.row.description"
                maxlength="20"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="采集类型" width="170">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.acquisitionType'"
              :rules="fromaDataRules.acquisitionType"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.acquisitionType || "" | staristicalStatus }}
              </span>
              <el-select
                v-model="scope.row.acquisitionType"
                v-else
                placeholder="请选择采集类型"
              >
                <el-option
                  v-for="item in statisticalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="起始地址" width="150">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.addr'"
              :rules="fromaDataRules.addr"
            >
              <!-- :rules="fromaDataRules.addr" -->
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.addr !== "" ? scope.row.addr : "" }}
              </span>
              <el-input
                v-else
                v-model.number="scope.row.addr"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="数据上限" width="130">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.valueCeiling'"
              :rules="fromaDataRules.valueCeiling"
            >
              <span v-if="scope.row.action == 'view'">
                {{
                  scope.row.valueCeiling !== "" ? scope.row.valueCeiling : ""
                }}
              </span>
              <el-input
                v-else
                v-model="scope.row.valueCeiling"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="数据下限" width="130">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.valueFloor'"
              :rules="fromaDataRules.valueFloor"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.valueFloor !== "" ? scope.row.valueFloor : "" }}
              </span>
              <el-input
                v-else
                v-model="scope.row.valueFloor"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="长度" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.len'"
              :rules="fromaDataRules.len"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.len | lengthStatus }}
              </span>
              <el-select
                v-model="scope.row.len"
                v-else
                placeholder="请选择长度"
              >
                <el-option
                  v-for="item in lengthList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="采集队列频次" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.freq'"
              :rules="fromaDataRules.freq"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.freq | acquisitionStatus }}
              </span>
              <el-select
                v-model="scope.row.freq"
                v-else
                placeholder="请选择采集队列频次"
              >
                <el-option
                  v-for="item in AcquisitionFrequency"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="解码类型" width="150">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.type'"
              :rules="fromaDataRules.type"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.type | decodeStatus }}
              </span>
              <el-select
                v-model="scope.row.type"
                v-else
                placeholder="请选择解码类型"
              >
                <el-option
                  v-for="item in decodeType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="功能码" width="150">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.fc'"
              :rules="fromaDataRules.fc"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.fc | funStatus }}
              </span>
              <el-select
                v-model="scope.row.fc"
                v-else
                placeholder="请选择功能码"
              >
                <el-option
                  v-for="item in FunCodeType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column label="位操作模式" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.bitMode'"
              :rules="fromaDataRules.bitMode"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.bitMode | operationStatus }}
              </span>
              <el-select
                v-model="scope.row.bitMode"
                v-else
                placeholder="请选择位操作模式"
              >
                <el-option
                  v-for="item in OperationMode"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="输入取反" width="150">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.bitNot ? "取反" : "不取反" }}
              </span>
              <el-select
                v-model="scope.row.bitNot"
                v-else
                placeholder="请选择输入取反"
                :disabled="scope.row.bitMode != 1"
              >
                <el-option
                  v-for="item in opnotFlagList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="位号" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.bitNum'"
              :rules="fromaDataRules.bitNum"
              ><!--   -->
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.bitNum }}
              </span>
              <el-input
                v-else
                v-model="scope.row.bitNum"
                :disabled="scope.row.bitMode != 1"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="字节号" width="150">
          <template slot-scope="scope">
            <el-form-item>
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.byteNum | byteStatus }}
              </span>
              <el-select
                v-model="scope.row.byteNum"
                v-else
                placeholder="请选择字节号"
                :disabled="scope.row.bitMode != 2"
              >
                <el-option
                  v-for="item in byteType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="k" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.k'"
              :rules="fromaDataRules.k"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.k || "0" }}
              </span>
              <el-input v-else v-model="scope.row.k" maxlength="8"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="b" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.b'"
              :rules="fromaDataRules.b"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.b || "0" }}
              </span>
              <el-input v-else v-model="scope.row.b" maxlength="8"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="字节序" width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="'dataPointList.' + scope.$index + '.endian'"
              :rules="fromaDataRules.endian"
            >
              <span v-if="scope.row.action == 'view'">
                {{ scope.row.endian | treatmentStatus }}
              </span>
              <el-select
                v-model="scope.row.endian"
                v-else
                placeholder="请选择字节序"
              >
                <el-option
                  v-for="item in TreatmentMethod"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.action == 'view'">
              <el-form-item>
                <el-button type="text" @click="handleEditData(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="handleDelData(scope.row, scope.$index)"
                  >删除</el-button
                >
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item>
                <el-button
                  type="text"
                  @click="handleSaveData(scope.row, scope.$index)"
                  >保存</el-button
                >
                <el-button
                  type="text"
                  @click="handleCancel(scope.row, scope.$index)"
                  >取消</el-button
                >
              </el-form-item>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div class="tar mt-15">
      <!-- <Pagination v-if="total>=20" :total="total" :page.sync="searchForm.page" :limit.sync="searchForm.size" @pagination="fetchData" /> -->
    </div>
    <div class="mb-20 mt-10">
      <el-button type="primary" @click="handleAddRow()" :disabled="btnDisabled"
        ><i class="el-icon-plus"></i> 增加一行</el-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  dataType,
  AddressOffset,
  lengthList,
  AcquisitionFrequency,
  CRCList,
  decodeType,
  OperationMode,
  TreatmentMethod,
  opnotFlagList,
  StatisticalType,
  FunCodeTypeCopy,
  byteType,
} from "@/utils/gatewayArrays";

import {
  staristicalStatus,
  dataTypeStatus,
  addressStatus,
  opnotFlagStatus,
  lengthStatus,
  acquisitionStatus,
  crcStatus,
  variableStatus,
  operationStatus,
  treatmentStatus,
  byteStatus,
  decodeStatus,
  funStatus,
} from "@/utils/gatewayFilters";
import applicationApi from "@/api/application";
import { regexs } from "@/utils/validate";
import store from "@/store";
export default {
  name: "tableItem",
  props: {
    configId: Number,
    tepInfoObj: {
      type: Object,
      defalut: {},
    },
    gatawayTempId: Number,
  },
  //import引入的组件需要注入到对象中才能使用
  components: {},
  filters: {
    staristicalStatus,
    dataTypeStatus,
    addressStatus,
    opnotFlagStatus,
    lengthStatus,
    acquisitionStatus,
    crcStatus,
    variableStatus,
    operationStatus,
    treatmentStatus,
    byteStatus,
    decodeStatus,
    funStatus,
  },
  data() {
    //这里存放数据
    const validateNumber = (rule, value, callback) => {
      if (Number(value) > 100000) {
        callback(new Error("不能大于100000"));
      } else {
        callback();
      }
    };
    const validateBitNum = (rule, value, callback) => {
      let obj = {};
      this.datePointForm.dataPointList.forEach((el) => {
        if (el.action == "edit" || el.action == "add") {
          obj = el;
        }
      });
      if (obj.bitMode === 1 && value === "") {
        callback(new Error("位号不能为空"));
      }
      if (value !== "" && Number(value) > 15) {
        callback(new Error("不能大于15"));
      }
      callback();
    };
    return {
      tepInfoObjRep: {},
      gatawayTempIdRep: null,
      action: "create",
      btnDisabled: false,
      dataPointList: [], // 列表数据
      datePointForm: {
        dataPointList: [],
      },
      statisticalList: StatisticalType, // 统计类型
      dataTypeList: dataType, // 数据类型
      AddressOffsetList: AddressOffset, // 首地址偏移
      lengthList: lengthList, // 长度
      AcquisitionFrequency: AcquisitionFrequency, // 采集频率
      CRCList: CRCList, // CRC字节序
      decodeType: decodeType, // 变量类型
      OperationMode: OperationMode, // 操作模式
      TreatmentMethod: TreatmentMethod, // 处理方式
      opnotFlagList: opnotFlagList, // 取反
      FunCodeType: FunCodeTypeCopy, // 功能码
      byteType: byteType, // 字节号
      basicPointLists: [],
      configure: {
        //配置详情
        basicPointList: [], //基本点
        virtualPointList: [], //虚拟点
        alertRuleList: [], //报警点
      },
      fromaDataRules: {
        name: [{ required: true, message: "名称不能为空", trigger: "submit" }],
        bitNum: [{ validator: validateBitNum, trigger: "submit" }],
        addr: [
          { required: true, message: "开始地址不能为空", trigger: "submit" },
          { type: "number", message: "请输入数字", trigger: "submit" },
          { validator: validateNumber, trigger: "submit" },
        ],
        acquisitionType: [
          { required: true, message: "请选择采集类型", trigger: "submit" },
        ],
        len: [{ required: true, message: "请选择长度", trigger: "submit" }],
        valueCeiling: [
          {
            pattern: regexs.integerKB,
            message: "请输入两位小数以内",
            trigger: "submit",
          },
        ],
        valueFloor: [
          {
            pattern: regexs.integerKB,
            message: "请输入两位小数以内",
            trigger: "submit",
          },
        ],
        type: [
          { required: true, message: "请选择解码类型", trigger: "submit" },
        ],
        fc: [{ required: true, message: "请选择功能码", trigger: "submit" }],
        freq: [
          { required: true, message: "请选择采集队列频次", trigger: "submit" },
        ],
        crcOrder: [
          { required: true, message: "请选择CRC字节序", trigger: "submit" },
        ],
        bitMode: [
          { required: true, message: "请选择位操作模式", trigger: "submit" },
        ],
        k: [{ required: true, message: "请输入数字", trigger: "submit" }],
        b: [{ required: true, message: "请输入数字", trigger: "submit" }],
        endian: [
          { required: true, message: "请选择字节序", trigger: "submit" },
        ],
      },
    };
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //如果是  新增  移除缓存中的值
    if (this.$route.query.action === "add") {
      window.localStorage.clear(); 
      // const Basic_Data =
      //   localStorage.getItem("basicRelationData") &&
      //   JSON.parse(localStorage.getItem("basicRelationData"));
      // if (Basic_Data && Basic_Data.length !== 0) {
      //   window.localStorage.removeItem("basicRelationData");
      // }
    }
    this.tepInfoObjRep = this.tepInfoObj;
    this.gatawayTempIdRep = this.gatawayTempId;
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.fetchData();
  },
  //方法集合
  methods: {
    /** 初始化 */
    async fetchData() {
      this.datePointForm.dataPointList = [];
      //详情  change
      if (this.gatawayTempIdRep) {
        const { data } = await applicationApi.pointsDetails(
          this.gatawayTempIdRep
        );
        this.tepInfoObjRep = {
          name: data.name,
          deviceType: data.deviceType,
          manufacturer: data.manufacturer,
          deviceModel: data.deviceModel,
          driveType: data.driveType,
        };
        if (data.configure) {
          //回显拿到所有数据点值，编辑时使用
          this.configure.basicPointList = data.configure.basicPointList; //基本点
          this.configure.virtualPointList = data.configure.virtualPointList;
          this.configure.alertRuleList = data.configure.alertRuleList;

          data.configure.basicPointList.forEach((it) => {
            this.datePointForm.dataPointList.push({
              ...it,
              acquisitionType:
                it.acquisitionType !== "" ? it.acquisitionType : "",
              offset: it.offset !== "" ? it.offset : "",
              addr: it.addr !== "" ? it.addr : "",
              valueCeiling:
                it.valueCeiling !== "" && it.valueCeiling !== undefined
                  ? it.valueCeiling
                  : "",
              valueFloor:
                it.valueFloor !== "" && it.valueFloor !== undefined
                  ? it.valueFloor
                  : "",
              len: it.len !== "" ? it.len : "",
              freq: it.freq !== "" ? it.freq : "",
              type: it.type !== "" ? it.type : "",
              fc: it.fc !== "" ? it.fc : "",
              bitNot: it.bitNot,
              bitMode: it.bitMode !== "" ? it.bitMode : "",
              bitNum:
                it.bitNum !== "" && it.bitNum !== undefined ? it.bitNum : "",
              byteNum: it.byteNum !== "" ? it.byteNum : "",
              k: it.k !== "" ? it.k : "",
              b: it.b !== "" ? it.b : "",
              endian: it.endian !== "" ? it.endian : "",
              action: "view",
              id: data.id,
            });
          });
        }
      }
    },
    /** 添加一行 */
    handleAddRow() {
      this.datePointForm.dataPointList.push({
        action: "add",
        type: 0,
        fc: 1,
        sort: 100,
        len: 1,
        freq: 1,
        bitNot: false,
        bitMode: 0,
        k: 1,
        b: 0,
        endian: 0,
        byteNum: 0,
        bitNum: "",
      });
      setTimeout(() => {
        this.$refs.dataList.bodyWrapper.scrollTop =
          this.$refs.dataList.bodyWrapper.scrollHeight + 50;
      }, 300);
    },
    /** 数据编辑 */
    handleEditData(row) {
      row.action = "edit";
    },
    /** 数据删除 */
    handleDelData(row, index) {
      this.$confirm("是否确认删除？", "删除", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const configure = this.configure;
        const blists = [];
        if (this.configure.basicPointList.length !== 0) {
          this.configure.basicPointList.forEach((item, indexs) => {
            if (index !== indexs) {
              blists.push(item);
            }
          });
        }
        this.configure.basicPointList = blists;
        // 删除操作（更新接口）
        store.dispatch(
          "tempImport/setBasicPointLists",
          this.configure.basicPointList
        );
        const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
        applicationApi
          .pointsEdit(this.gatawayTempIdRep, concatForm)
          .then((r) => {
            this.$message.success("删除成功!");
            this.gatawayTempIdRep = r.data.id;
            this.fetchData();
          });
      });
    },
    /** 验证 */
    validateField(row, index) {
      let result = true;
      for (let item of this.$refs["tableForm"].fields) {
        if (Number(item.prop.split(".")[1]) == index) {
          this.$refs["tableForm"].validateField(item.prop, (error) => {
            if (error != "") {
              result = false;
            }
          });
        }
        if (!result) break;
      }
      return result;
    },
    // 保存操作，保存所有的数据点
    handleSaveData(row, index) {
      if (!this.validateField("tableForm", index)) return;
      // 往数组中累加存值
      const Basic_Data = JSON.parse(localStorage.getItem("basicRelationData"));
      if (Basic_Data == null) {
        const basicRelationData = [];
        basicRelationData.push({
          id: index,
          name: row.name,
          type: "BASIC",
          groupName: row.name + "#BASIC",
        });
        window.localStorage.setItem(
          "basicRelationData",
          JSON.stringify(basicRelationData)
        );
        store.dispatch("tempImport/setBasicRelationDatas", basicRelationData);
      } else {
        localStorage.removeItem("basicRelationData");
        Basic_Data.push({
          id: index,
          name: row.name,
          type: "BASIC",
          groupName: row.name + "#BASIC",
        });
        window.localStorage.setItem(
          "basicRelationData",
          JSON.stringify(Basic_Data)
        );
        store.dispatch("tempImport/setBasicRelationDatas", Basic_Data);
      }

      const configure = this.configure;
      const blists = [];

      if (this.configure.basicPointList.length !== 0) {
        this.configure.basicPointList.forEach((item, indexs) => {
          if (index !== indexs) {
            blists.push(item);
          }
        });
      }
      blists.push(row);
      let form = {
        name: row.name,
        description: row.description,
        acquisitionType: row.acquisitionType, // 统计类型
        addr: row.addr, // 开始地址
        valueCeiling: row.valueCeiling, // 数据上限
        valueFloor: row.valueFloor, // 数据下限
        len: row.len, // 长度
        freq: row.freq, // 采集频率
        type: row.type, // 解码类型
        fc: row.fc, // 功能码
        bitNot: row.bitNot, // 输入取反
        bitMode: row.bitMode, // 位操作模式
        bitNum: row.bitNum, // 位号
        byteNum: row.byteNum, // 字节号
        k: row.k, // k
        b: row.b, // b
        endian: row.endian, // 字节序
        id: 1, //公式使用
      };
      this.configure.basicPointList = blists;
      const concatForm = Object.assign({ ...this.tepInfoObjRep, configure });
      // 新增/编辑 (共用编辑接口)
      applicationApi.pointsEdit(this.gatawayTempIdRep, concatForm).then((r) => {
        if (r.data.configure.basicPointList.length !== 0) {
          this.basicPointLists = r.data.configure.basicPointList;
          //存值，提交整个页面时使用
          store.dispatch("tempImport/setBasicPointLists", this.basicPointLists);
          //保存每一条存下基本点
          window.localStorage.setItem(
            "basicPointLists",
            JSON.stringify(this.basicPointLists)
          );
        }
        this.$message.success("保存成功");
        this.gatawayTempIdRep = r.data.id;
        this.fetchData();
      });
    },

    /** 表格中的取消 */
    handleCancel(row, index) {
      this.$alert("填写内容未保存，是否确认取消?", "取消", {
        cancelButtonClass: "btn-custom-cancel",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action === "confirm") {
            if (row.id) {
              // 编辑的取消
              this.resetField("tableForm", index);
              row.action = "view";
            } else {
              this.datePointForm.dataPointList.splice(index, 1);
            }
          }
        },
      });
    },

    //对部分表单字段进行重置
    resetField(form, index) {
      this.$refs[form].fields.forEach((item) => {
        if (item.prop.split(".")[1] == index) {
          item.resetField();
        }
      });
    },
  },

  watch: {
    "datePointForm.dataPointList": {
      handler(val) {
        if (val.length > 0) {
          val.forEach((it) => {
            if (!it.id) {
              this.btnDisabled = true;
              return;
            } else {
              this.btnDisabled = false;
            }
          });
        } else {
          this.btnDisabled = false;
        }
      },
      deep: true,
    },

    configId: {
      handler(val) {
        if (val !== -1) {
          this.fetchData();
        } else {
          this.btnDisabled = true;
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-form {
  .bg_table {
    .el-table__fixed {
      height: 451px !important;
    }
  }
}
</style>
