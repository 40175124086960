<template>
  <div class="q-staff">
    <q-crud ref="crud" :schema="schema" />
  </div>
</template>

<script>
import api from "@/api/corpUser";
import roleApi from "@/api/corpRole";
import BizCode from "@/utils/BizCode";
// @ is an alias to /src

export default {
  name: "QStaff",
  data() {
    return {
      schema: {
        xType: "q-crud",
        api: api,
        subjectKey: "q-staff", //关键字英文
        subjectName: "内部用户", //项目名中文
        dialogWidth: "40%", // 弹框宽度
        addAuth:'CORP_USER:CREATE',
        add: () => {
          this.$router.push({
            name: "EditStaff",
            query: {
              mode: "add",
            },
          });
        },
        //顶部搜素  delete
        searchFields: [
          {
            xType: "q-field",
            fType: "text",
            name: "信息搜索",
            label: "信息搜索",
            placeholder: "搜索内部用户姓名或手机号码关键词",
            prop: "key",
            style: {
              width: "300px",
            },
          },
          {
            xType: "q-field",
            fType: "q-select",
            name: "角色",
            label: "角色",
            placeholder: "全部",
            remoteOnce: roleApi.getAll,
            prop: "roleId",
          },
          {
            xType: "q-field",
            fType: "q-select",
            name: "启用状态",
            label: "启用状态",
            placeholder: "全部",
            opts: [
              {
                label: "已启用",
                value: false,
              },
              {
                label: "已禁用",
                value: true,
              },
            ],
            prop: "disabled",
          },
        ],
        //表格内容区域 delete
        gridColumns: [
          {
            xType: "q-table-column",
            label: "内部用户姓名",
            prop: "name",
          },
          {
            xType: "q-table-column",
            label: "角色",
            prop: "role.name",
          },
          {
            xType: "q-table-column",
            label: "手机号码",
            prop: "phone",
          },
          {
            xType: "q-table-column",
            label: "邮箱",
            prop: "email",
          },
          {
            xType: "q-table-column",
            label: "账号状态",
            prop: "disabled",
            render: (row) => {
              return row.disabled ? "已禁用" : "已启用";
            },
          },
          {
            xType: "q-table-column",
            label: "操作",
            prop: "address",
            width: 250,
            // eslint-disable-next-line no-unused-vars
            tpl: (row) => {
              //这里空下来做自定义渲染
              return [
                {
                  xType: "q-button",
                  text: "查看",
                  type: "text",
                  //这里没看明白auth
                  auth:'CORP_USER:GET',
                  click: () => {
                    this.$router.push({
                      name: "EditStaff",
                      query: {
                        id: row.id,
                        mode: "view",
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  text: "编辑",
                  type: "text",
                  auth:'CORP_USER:UPDATE',
                  click: () => {
                    this.$router.push({
                      name: "EditStaff",
                      query: {
                        id: row.id,
                        mode: "update",
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  type: "text",
                  auth:'CORP_USER:ENABLE_OR_DISABLE',
                  text: row.disabled ? "启用" : "禁用",
                  click: async () => {
                    try {
                      await this.$confirm(
                        `确认${row.disabled ? "启用" : "禁用"}?`,
                        "提示",
                        {
                          confirmButtonText: "确定",
                          cancelButtonText: "取消",
                          type: "warning",
                        }
                      );
                      //请求服务器
                      const result = await api.enable(row.id, !row.disabled);
                      if (result.code === BizCode.OK) {
                        //若删除成功则刷新数据库
                        this.$refs["crud"].syncServer();
                      }
                    } catch (err) {
                      //取消按钮
                      if (err !== "cancel") {
                        throw err;
                      }
                    }
                  },
                },
                {
                  xType: "q-button",
                  text: "重置密码",
                  auth:'CORP_USER:RESET_PASSWORD',
                  type: "text",
                  click: async () => {
                    try {
                      await this.$confirm(`确认重置密码?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      });
                      //请求服务器
                      const result = await api.reset(row.id);
                      if (result.code === BizCode.OK) {
                        //若删除成功则刷新数据库
                        this.$refs["crud"].syncServer();
                      }
                    } catch (err) {
                      //取消按钮
                      if (err !== "cancel") {
                        throw err;
                      }
                    }
                  },
                },
                {
                  xType: "q-button",
                  text: "删除",
                  auth:'CORP_USER:REMOVE',
                  type: "text",
                  click: async () => {
                    try {
                      await this.$confirm("确认删除?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      });
                      //请求服务器
                      const result = await api.del(row.id);
                      if (result.code === BizCode.OK) {
                        //若删除成功则刷新数据库
                        this.$refs["crud"].syncServer();
                      }
                    } catch (err) {
                      //取消按钮
                      if (err !== "cancel") {
                        throw err;
                      }
                    }
                  },
                },
              ];
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
