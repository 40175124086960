<template>
  <div>
    <div class="page_title">模板管理</div>
    <!-- 顶部搜索区 -->
    <div class="page-info">
      <el-button type="primary" @click="handleTemplateAdd">新增模板</el-button>
    </div>
    <el-form
      :inline="true"
      :model="searchForm"
      class="demo-form-inline"
      label-width="auto"
    >
      <el-form-item label="模板名称">
        <el-input
          v-model="searchForm.key"
          placeholder="请输入模板名称、设备生产厂家、设备型号等关键字"
          clearable
          @blur="gatewayTemplateList()"
        ></el-input>
      </el-form-item>
      <el-form-item label="设备类别：">
        <el-select
          v-model="searchForm.deviceType"
          placeholder="请选择设备类别"
          clearable
          @change="getTotal()"
        >
          <el-option
            v-for="item in deviceTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button clearable @click="onSearchInfo()">查询</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格区 -->
    <el-table :data="tempalteManageList" style="width: 100%">
      <el-table-column prop="name" label="模板名称"> </el-table-column>
      <el-table-column prop="deviceType" label="设备类别">
        <template slot-scope="scope">
          {{ scope.row.deviceType | deviceTypeStatus }}
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer" label="设备生产厂家">
      </el-table-column>
      <el-table-column prop="deviceModel" label="设备型号"> </el-table-column>
      <el-table-column prop="driveType" label="驱动类型">
        <template slot-scope="scope">
          {{ scope.row.driveType | driveTypeStatus }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleRead(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button type="text" size="small" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="handleDataConfig(scope.row)"
            >配置</el-button
          >
          <el-button type="text" size="small" @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="total >= 10"
      :total="total"
      :page.sync="searchForm.page"
      :limit.sync="searchForm.size"
      @pagination="
        searchForm.page = searchForm.page;
        searchForm.size = searchForm.size;
        changeRoute();
      "
    />
  </div>
</template>

<script>
import applicationApi from "@/api/application";
import { deviceTypeList } from "@/utils/gatewayArrays";
import { deviceTypeStatus, driveTypeStatus } from "@/utils/gatewayFilters";
export default {
  data() {
    return {
      total: 0,
      searchForm: {
        page: 1, //页码
        size: 10, //每页显示几条
        key: "", //关键字
        deviceType: "", //设备类型
        driveType: "", //驱动类型
      },
      deviceTypeList: deviceTypeList,
      tempalteManageList: [],
    };
  },
  filters: {
    deviceTypeStatus,
    driveTypeStatus,
  },
  methods: {
    getTotal() {
      this.gatewayTemplateList();
    },
    // 查询
    onSearchInfo() {
      if (this.total <= 10) {
        this.searchForm.page = 1;
      }
      this.gatewayTemplateList();
    },
    // 模板列表
    async gatewayTemplateList() {
      const { data } = await applicationApi.gatewayTemplateList(
        this.searchForm
      );
      if (data) {
        this.tempalteManageList = data.records;
        this.total = data.total;
      } else {
        this.tempalteManageList = [];
      }
    },
    // 改变路由
    changeRoute(data) {
      this.$router.push({
        name: "TemplateManage",
        query: {
          page: this.searchForm.page,
          size: this.searchForm.size,
          key: this.searchForm.key,
          deviceType: this.searchForm.deviceType,
          t: new Date().getTime(),
        },
      });
      this.gatewayTemplateList();
    },
    // 新增
    handleTemplateAdd() {
      this.$router.push({
        name: "TemplateEdit",
        query: {
          action: "add",
        },
      });
    },
    // 查看
    handleRead(row) {
      this.$router.push({
        name: "TemplateEdit",
        query: {
          action: "read",
          id: row.id, // 网关模板id
        },
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: "TemplateEdit",
        query: {
          action: "edit",
          id: row.id, // 网关模板id
        },
      });
    },
    // 配置
    handleDataConfig(row) {
      this.$router.push({
        name: "TemplateDataConfig",
        query: {
          action: "edit",
          id: row.id, // 网关模板id
          driveType: row.driveType,
        },
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //删除接口
          applicationApi.pointsDelete(row.id).then((r) => {
            this.$message.success("删除成功");
            this.gatewayTemplateList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除!",
          });
        });
    },
  },
  created() {
    this.gatewayTemplateList();
  },
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
  .el-input {
    width: 350px !important;
  }
}
.page-info {
  display: flex;
  justify-content: flex-end;
}
</style>