var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-view-customer"},[_c('q-page',{attrs:{"schema":{
				xType: 'q-page',
				title: '查看客户',
				items: [
					{
						id: "q-customer-form",
						labelWidth: '150px',
						xType: 'q-form',
						disabled: true,
						items: [
							{
								xType: 'q-field',
								fType: 'q-hidden',
								name: 'mode',
								label: 'mode',
								prop: 'mode',
							},
							{
								xType: 'q-field',
								fType: 'q-hidden',
								name: 'id',
								label: 'id',
								prop: 'id',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '客户公司名称',
								label: '客户公司名称',
								placeholder: '请输入租户公司名称',
								prop: 'name',
								rules: [
									{
										required: true,
										message: '请输入租户公司名称',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '客户公司简称',
								label: '客户公司简称',
								placeholder: '请输入租户简称',
								prop: 'simpleName',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '负责人',
								label: '负责人',
								prop: 'managerName',
								placeholder: '请输入负责人姓名',
								rules: [
									{
										required: true,
										message: '请输入负责人姓名',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '负责人手机',
								label: '负责人手机',
								prop: 'managerPhone',
								placeholder: '请输入负责人手机号码',
								rules: [
									{
										required: true,
										message: '请输入负责人手机号码',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '企业邮箱',
								label: '企业邮箱',
								prop: 'email',
								placeholder: '请输入企业邮箱',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '公司电话',
								label: '公司电话',
								prop: 'phone',
								placeholder: '请输入租户公司电话',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '社会统一信用代码',
								label: '社会统一信用代码',
								prop: 'uscc',
								placeholder: '请输入社会统一信用代码',
								rules: [
									{
										required: true,
										message: '请输入社会统一信用代码',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-industry-cascader',
								name: '所属行业',
								label: '所属行业',
								prop: 'industry',
								placeholder: '请选择所属行业',
								rules: [
									{
										required: true,
										message: '请选择所属行业',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '客户传真',
								label: '客户传真',
								prop: 'fax',
								placeholder: '请输入租户公司传真',
							},
							{
								xType: 'q-field',
								fType: 'q-location-cascader',
								name: '客户地址',
								label: '客户地址',
								prop: 'location',
								placeholder: '请选择省份、市、县/区',
								rules: [
									{
										required: true,
										message: '请选择省份、市、县/区',
										trigger: 'blur',
									} ],
							},
							{
								xType: 'q-field',
								fType: 'q-address',
								name: '',
								label: '',
								prop: 'address',
							},
							{
								xType: 'q-field',
								fType: 'q-subscription-list',
								name: '订阅信息',
								label: '订阅信息',
								//labelWidth: '150px',
								placeholder: '请输入订阅信息',
								prop: 'subscription',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '账号状态',
								label: '账号状态',
								placeholder: '请输入账号状态',
								prop: 'enabled',
							},
							{
								xType: 'q-field',
								fType: 'q-input',
								name: '备注',
								label: '备注',
								placeholder: '请输入备注',
								prop: 'description',
							},
              {
								xType: 'q-field',
								fType: 'q-image-upload',
								name: 'logo',
								label: 'logo',
								prop: 'logo',
							} ],
					},
					{
						xType: 'q-button-panel',
						items: [
							{
								xType: 'q-button',
								text: '返回',
								click: function () {
									_vm.$back();
								},
							} ],
					} ],
			},"path":"/"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }