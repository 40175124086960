<template>
  <div class="q-version">
    <q-crud ref="crud" :schema="schema" />
  </div>
</template>

<script>
import api from "@/api/appVersion";
import applicationApi from "@/api/application";
// import { findComponentById } from '@/utils/index';
import BizCode from "@/utils/BizCode";

// @ is an alias to /src

export default {
  name: "QVersion",
  data() {
    return {
      schema: {
        xType: "q-crud",
        api: api,
        subjectKey: "q-version", //关键字英文
        subjectName: "版本", //项目名中文
        addAuth: "VERSION:CREATE",
        add: () => {
          this.$router.push({
            name: "EditVersion",
            query: {
              mode: "add",
            },
          });
        },
        searchFields: [
          {
            xType: "q-field",
            fType: "text",
            name: "信息搜索",
            label: "信息搜索",
            placeholder: "搜索版本名称关键词",
            prop: "key",
            style: {
              width: "300px",
            },
          },
          {
            xType: "q-field",
            fType: "q-select",
            name: "应用名称",
            label: "应用名称",
            placeholder: "全部",
            remoteOnce: applicationApi.getAll,
            prop: "appId",
          },
        ],
        gridColumns: [
          {
            xType: "q-table-column",
            label: "版本名称",
            prop: "name",
          },
          {
            xType: "q-table-column",
            label: "应用名称",
            prop: "appName",
          },

          {
            xType: "q-table-column",
            label: "拥有权限",
            width: 350,
            render: (row) => {
              return row.resourceNames.map((it) => `【${it}】`).join("");
            },
          },
          {
            xType: "q-table-column",
            label: "创建人",
            prop: "createdBy",
          },
          {
            xType: "q-table-column",
            label: "创建日期",
            render: (row) => {
              return this.$options.filters._date(row.createdDate);
            },
          },
          {
            xType: "q-table-column",
            label: "操作",
            prop: "address",
            width: 150,
            // eslint-disable-next-line no-unused-vars
            tpl: (row) => {
              //这里空下来做自定义渲染
              return [
                {
                  xType: "q-button",
                  text: "查看",
                  auth:'VERSION:GET',
                  type: "text",
                  click: () => {
                    this.$router.push({
                      name: "EditVersion",
                      query: {
                        id: row.id,
                        mode: "view",
                      },
                    });
                  },
                },
                {
                  xType: "q-button",
                  text: "编辑",
                  auth:'VERSION:UPDATE',
                  type: "text",
                  click: () => {
                    // this.$refs['crud'].update(event, row);
                    this.$router.push({
                      name: "EditVersion",
                      query: {
                        id: row.id,
                        mode: "update",
                      },
                    });
                  },
                },

                {
                  xType: "q-button",
                  text: "删除",
                  type: "text",
                  auth:'VERSION:REMOVE',
                  click: async () => {
                    try {
                      await this.$confirm("确认删除?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      });
                      //请求服务器
                      const result = await api.del(row.id);
                      if (result.code === BizCode.OK) {
                        //若删除成功则刷新数据库
                        this.$refs["crud"].syncServer();
                      }
                    } catch (err) {
                      //取消按钮
                      if (err !== "cancel") {
                        throw err;
                      }
                    }
                  },
                },
              ];
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped lang="scss"></style>
