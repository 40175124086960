<template>
    <div class="q-customer">
        <q-crud ref="crud" :schema="schema"/>
        <el-dialog
                @close="radioboxes = []"
                :modal="true"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                title="订阅"
                :visible.sync="dialogVisible"
        >
            <div>
                <q-form
                        path="/q-subcription-form"
                        ref="q-subcription-form"
                        :schema="{
            xType: 'q-form',
            items: [
              {
                xType: 'q-field',
                fType: 'q-hidden',
                name: 'id',
                label: 'id',
                prop: 'id',
              },
              ...this.radioboxes,
            ],
          }"
                />
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ok"> 确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from "@/api/customer";
    import {getIndustry} from "@/utils/array";
    import appVersionApi from "@/api/appVersion";
    import Enumerable from "linq";
    import BizCode from "@/utils/BizCode";
    import Location from "@/assets/json/Location.json";

    // import { findComponentById } from "@/utils/index";
    // import Vue from "vue";
    // @ is an alias to /src

    export default {
        name: "QCustomer",

        data() {
            return {
                radioboxes: [],
                dialogVisible: false,
                schema: {
                    xType: "q-crud",
                    add: () => {
                        this.$router.push({
                            name: "EditCustomer",
                            query: {
                                mode: "add",
                            },
                        });
                    },
                    addAuth: "CUSTOMER:CREATE",
                    api: api,
                    subjectKey: "q-customer", //关键字英文
                    subjectName: "客户", //项目名中文
                    searchFields: [
                        {
                            xType: "q-field",
                            fType: "text",
                            name: "信息搜索",
                            label: "信息搜索",
                            placeholder: "搜索客户公司名称、负责人或手机号关键词",
                            prop: "key",
                            style: {
                                width: "300px",
                            },
                        },
                        {
                            xType: "q-field",
                            fType: "q-select",
                            name: "启用状态",
                            label: "启用状态",
                            placeholder: "全部",
                            opts: [
                                {
                                    label: "全部",
                                    value: "",
                                },
                                {
                                    label: "正常",
                                    value: false,
                                },
                                {
                                    label: "停用",
                                    value: true,
                                },
                            ],
                            prop: "disabled",
                        },
                    ],
                    gridColumns: [
                        {
                            xType: "q-table-column",
                            label: "客户公司名称",
                            prop: "name",
                        },
                        {
                            xType: "q-table-column",
                            label: "负责人",
                            prop: "managerName",
                        },
                        {
                            xType: "q-table-column",
                            label: "负责人手机",
                            prop: "managerPhone",
                        },
                        {
                            xType: "q-table-column",
                            label: "所属行业",
                            render: (row) => {
                                return this.industryFiliter(
                                    row.primaryIndustryId,
                                    row.secondaryIndustryId,
                                    row.minorIndustryId
                                );
                            },
                        },
                        {
                            xType: "q-table-column",
                            label: "所属省份",
                            prop: "province",
                            render: (row) => {
                                return this.getProvince(row.province);
                            },
                        },
                        {
                            xType: "q-table-column",
                            label: "账号状态",
                            render: (row) => {
                                return row.disabled ? "停用" : "正常";
                            },
                        },
                        {
                            xType: "q-table-column",
                            label: "操作",
                            prop: "address",
                            width: 200,
                            // eslint-disable-next-line no-unused-vars
                            tpl: (row) => {
                                //这里空下来做自定义渲染
                                return [
                                    {
                                        xType: "q-button",
                                        text: "查看",
                                        auth: "CUSTOMER:GET",
                                        type: "text",
                                        click: () => {
                                            this.$router.push({
                                                name: "ViewCustomer",
                                                query: {
                                                    mcid: row.mcid,
                                                },
                                            });
                                        },
                                    },
                                    {
                                        xType: "q-button",
                                        text: "编辑",
                                        auth: "CUSTOMER:UPDATE",
                                        type: "text",
                                        click: () => {
                                            this.$router.push({
                                                name: "EditCustomer",
                                                query: {
                                                    mcid: row.mcid,
                                                    mode: "update",
                                                },
                                            });
                                        },
                                    },

                                    {
                                        xType: "q-button",
                                        text: "订阅",
                                        auth: "CUSTOMER:SUBSCRIBE",
                                        type: "text",
                                        // eslint-disable-next-line no-unused-vars
                                        click: async (event) => {
                                            //this.$refs["crud"].update(event, row);
                                            this.dialogVisible = true;
                                            window.setTimeout(async () => {
                                                const [
                                                    allVersionResult,
                                                    subcriptionResult,
                                                ] = await Promise.all([
                                                    appVersionApi.listAll(),
                                                    api.getSubscription(row.mcid),
                                                ]);
                                                if (
                                                    allVersionResult.code === BizCode.OK &&
                                                    subcriptionResult.code === BizCode.OK
                                                ) {
                                                    //合并订阅和已经订阅的信息
                                                    //let subcription = subcriptionResult.data ?? [];
                                                    let allVersion = allVersionResult.data ?? [];
                                                    let subcription = subcriptionResult.data ?? [];
                                                    // for (let version of allVersion) {
                                                    //   let sub = subcription.find(
                                                    //     (s) => s.versionId === version.id
                                                    //   );
                                                    //   if (sub !== null && sub !== undefined) {
                                                    //     version.startDate = sub.startDate;
                                                    //     version.endDate = sub.endDate;
                                                    //     version.leftDays = sub.leftDays;
                                                    //     version.leftDays = sub.leftDays;
                                                    //     version.subscribed = true;
                                                    //   } else {
                                                    //     version.subscribed = false;
                                                    //   }
                                                    // }
                                                    //按照category维度分组整理
                                                    let allVersionFields = Enumerable.from(allVersion)
                                                        .groupBy("$.category", null, (key, group) => {
                                                            return {
                                                                xType: "q-field",
                                                                fType: "q-radioboxes",
                                                                label: "",
                                                                allText: key,
                                                                name: key,
                                                                prop: key,
                                                                //rules: [{ required: true }],
                                                                opts: group
                                                                    .select((r) => {
                                                                        return {
                                                                            label: r.name,
                                                                            value: r.id,
                                                                        };
                                                                    })
                                                                    .toArray(),
                                                            };
                                                        })
                                                        .toArray();
                                                    //初始化表单观察者属性,此处未来要重点改进
                                                    const form = this.$refs["q-subcription-form"];
                                                    let obj = {};
                                                    for (let versionField of allVersionFields) {
                                                        //linq的强大,一部筛选出已经订阅的版本
                                                        const sub = Enumerable.from(
                                                            subcription
                                                        ).firstOrDefault((s) =>
                                                            Enumerable.from(versionField.opts).any(
                                                                (o) => s.versionId === o.value
                                                            )
                                                        );
                                                        //为已订阅赋值
                                                        if (sub !== undefined && sub !== null) {
                                                            obj[versionField.name] = {
                                                                selected: sub.versionId,
                                                                range: [sub.startDate, sub.endDate],
                                                                logo: sub.logo
                                                            };
                                                        } else {
                                                            obj[versionField.name] = {
                                                                selected: "",
                                                                range: [],
                                                                logo: ''
                                                            };
                                                        }
                                                    }
                                                    obj.id = row.mcid;
                                                    form.setData(obj);
                                                    this.radioboxes = [...allVersionFields];
                                                }
                                            }, 0);
                                        },
                                    },

                                    {
                                        xType: "q-button",
                                        text: row.disabled ? "启用" : "停用",
                                        auth: "CUSTOMER:ENABLE_OR_DISABLE",
                                        type: "text",
                                        click: async () => {
                                            try {
                                                await this.$confirm(
                                                    `确认${row.disabled ? "启用" : "停用"}?`,
                                                    "提示",
                                                    {
                                                        confirmButtonText: "确定",
                                                        cancelButtonText: "取消",
                                                        type: "warning",
                                                    }
                                                );
                                                //请求服务器
                                                const result = await api.enable(row.mcid, !row.disabled);
                                                if (result.code === BizCode.OK) {
                                                    //若删除成功则刷新数据库
                                                    this.$refs["crud"].syncServer();
                                                }
                                            } catch (err) {
                                                //取消按钮
                                                if (err !== "cancel") {
                                                    throw err;
                                                }
                                            }
                                        },
                                    },
                                ];
                            },
                        },
                    ],
                    formFields: [
                        {
                            xType: "q-field",
                            fType: "q-hidden",
                            name: "mode",
                            label: "mode",
                            prop: "mode",
                        },
                        {
                            xType: "q-field",
                            fType: "q-hidden",
                            name: "id",
                            label: "id",
                            prop: "id",
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "客户公司名称",
                            label: "客户公司名称",
                            placeholder: "请输入租户公司名称",
                            prop: "name",
                            rules: [
                                {
                                    required: true,
                                    message: "请输入租户公司名称",
                                    trigger: "blur",
                                },
                            ],
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "客户公司简称",
                            label: "客户公司简称",
                            placeholder: "请输入租户简称",
                            prop: "simpleName",
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "负责人",
                            label: "负责人",
                            prop: "managerName",
                            placeholder: "请输入负责人姓名",
                            rules: [
                                {
                                    required: true,
                                    message: "请输入负责人姓名",
                                    trigger: "blur",
                                },
                            ],
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "负责人手机",
                            label: "负责人手机",
                            prop: "managerPhone",
                            placeholder: "请输入负责人手机号码",
                            rules: [
                                {
                                    required: true,
                                    message: "请输入负责人手机号码",
                                    trigger: "blur",
                                },
                            ],
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "企业邮箱",
                            label: "企业邮箱",
                            prop: "email",
                            placeholder: "请输入企业邮箱",
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "公司电话",
                            label: "公司电话",
                            prop: "phone",
                            placeholder: "请输入租户公司电话",
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "社会统一信用代码",
                            label: "社会统一信用代码",
                            prop: "uscc",
                            placeholder: "请输入社会统一信用代码",
                            rules: [
                                {
                                    required: true,
                                    message: "请输入社会统一信用代码",
                                    trigger: "blur",
                                },
                            ],
                        },
                        {
                            xType: "q-field",
                            fType: "q-industry-cascader",
                            name: "所属行业",
                            label: "所属行业",
                            prop: "industry",
                            placeholder: "请选择所属行业",
                            rules: [
                                {
                                    required: true,
                                    message: "请选择所属行业",
                                    trigger: "blur",
                                },
                            ],
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "客户传真",
                            label: "客户传真",
                            prop: "fax",
                            placeholder: "请输入租户公司传真",
                        },
                        {
                            xType: "q-field",
                            fType: "q-location-cascader",
                            name: "客户地址",
                            label: "客户地址",
                            prop: "location",
                            placeholder: "请选择省份、市、县/区",
                            rules: [
                                {
                                    required: true,
                                    message: "请选择省份、市、县/区",
                                    trigger: "blur",
                                },
                            ],
                        },
                        {
                            xType: "q-field",
                            fType: "q-text",
                            name: "",
                            label: "",
                            prop: "address",
                        },
                        {
                            xType: "q-field",
                            fType: "q-input",
                            name: "备注",
                            label: "备注",
                            placeholder: "请输入备注",
                            prop: "description",
                        },
                    ],
                },
                industry: [],
            };
        },
        created() {
            this.industryAll();
        },
        methods: {
            async industryAll() {
                this.industry = await getIndustry();
            },
            industryFiliter(first, second, minor) {
                let str = "";
                this.industry.forEach((item) => {
                    if (item.value === first) {
                        item.children.forEach((it) => {
                            if (it.value === second) {
                                it.children.forEach((el) => {
                                    if (el.value === minor) {
                                        str = el.label;
                                    } else {
                                        return false;
                                    }
                                });
                            }
                        });
                    }
                });
                return (str ?? "").split("-").length > 0 ? str.split("-")[1] : str; //修正所属行业的展示问题
            },
            //省份
            getProvince(adcode) {
                return (
                    Location.districts[0].districts.find((d) => d.adcode === adcode)
                        ?.name ?? adcode
                );
            },
            async ok() {
                const form = this.$refs["q-subcription-form"];
                const formData = form.getData();
                const {id: mcid, ...fields} = formData;
                form.validate(async (valid) => {
                    if (valid) {
                        let arr = [];
                        const keys = Object.keys(fields);
                        for (let key of keys) {
                            const item = fields[key];
                            arr.push({
                                versionId: item.selected,
                                startDate: item.range[0],
                                endDate: item.range[1],
                                logo: item.logo
                            });
                        }

                        //过滤掉未选择的项
                        arr = arr.filter(
                            (a) =>
                                a.versionId !== "" &&
                                a.versionId !== undefined &&
                                a.versionId !== null
                        );
                        const result = await api.subscribe(mcid, arr);

                        if (result.code === BizCode.OK) {
                            this.$refs["crud"].syncServer();
                            this.dialogVisible = false;
                        }
                    }

                    //if (valid) {
                    //   const grid = findComponentById(
                    //     `${this.schema.subjectKey}-data-grid`,
                    //     this
                    //   );
                    //   grid.setQueryParams(searchFrm.getData());
                    //   grid.syncServer();
                    // }
                });

                // let arr = [];
                // for (let key of Object.keys(formData)) {
                //   arr = [...arr, ...formData[key]];
                // }

                // const result = await api.bindCorpAuthority(this.$route.query.roleId, [
                //   ...arr,
                // ]);
                // if (result.code === BizCode.OK) {
                //   this.$back();
                // }
            },
        },
    };
</script>

<style scoped lang="scss"></style>
