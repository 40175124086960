import { request } from "@/utils/request";
import { loading } from "@/utils/index";
import BizCode from "@/utils/BizCode";

const BASE_PATH = "/corpRole";

//角色
class Api {
  //新增
  @loading()
  async add(form) {
    return request.post(`${BASE_PATH}`, form);
  }

  //修改
  @loading()
  async update(id, form) {
    return request.put(`${BASE_PATH}/${id}`, form);
  }

  /** 角色详情 */
  @loading({ delay: 0 })
  async detail(id) {
    return request.get(`${BASE_PATH}/${id}`);
  }

  /** 删除角色 */
  @loading()
  async del(id) {
    return request.delete(`${BASE_PATH}/${id}`);
  }

  /** 分页获取角色列表 */
  @loading({ delay: 0 })
  async getPageList(params) {
    return request.get(`${BASE_PATH}`, { params: params });
  }

  /**
   *
   * @returns
   */
  // eslint-disable-next-line no-unused-vars
  async getAll() {
    const result = await request.get(`${BASE_PATH}/listAll`);
    return result.code === BizCode.OK
      ? result.data.map((r) => {
          return { label: r.name, value: r.id };
        })
      : [];
  }

  //获取所有的角色权限
  @loading({ delay: 0 })
  async getAllAuthorities() {
    return request.get(`${BASE_PATH}/listCorpAuthority`);
  }

  @loading({ delay: 0 })
  async bindCorpAuthority(id, resources) {
    return request.get(
      `${BASE_PATH}/${id}/bindCorpAuthority?resources=${
        resources?.join ? resources.join(",") : ""
      }`
    );
  }
}

export default new Api();
